import { useEffect, useRef, useState } from "react";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

export const usePersonModalForm = ({
    config,
    headerTextOptions,
    onChange,
    personStaging,
    setCurrentSection,
    setHeaderText,
}) => {
    /**
     * useRef
     **/

    const isFirstRender = useRef(true);

    /**
     * useState
     **/

    const [selectedTitle, setSelectedTitle] = useState("");
    const [stateSelect, setStateSelect] = useState("");
    const [stateText, setStateText] = useState("");

    /**
     * useEffect
     **/

    useEffect(() => {
        setHeaderText(headerTextOptions.form);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Initialize values for stateSelect and stateText, if
     * `config.shouldEnableStateFieldToggle` is active
     */
    useEffect(() => {
        if (config.shouldEnableStateFieldToggle) {
            handleInitializeStateFieldValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personStaging]);

    /**
     * Toggle between whether stateSelect or stateText is active, if
     * `config.shouldEnableStateFieldToggle` is active
     */
    useEffect(() => {
        if (config.shouldEnableStateFieldToggle) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }

            toggleActiveStateValue();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personStaging.address?.country]);

    /**
     * End Hooks
     **/

    function handleInitializeStateFieldValues() {
        if (
            !personStaging.address?.country ||
            personStaging.address?.country === "US"
        ) {
            setStateSelect(personStaging.address?.state);
        } else {
            setStateText(personStaging.address?.state);
        }
    }

    function handleGoToSelectBase() {
        setCurrentSection(PersonModalNavigation.ADDRESS_FORMATTER);
    }

    function toggleActiveStateValue() {
        const activeStateValue =
            personStaging.address?.country === "US"
                ? stateSelect || ""
                : stateText || "";
        onChange({
            target: { name: "address.state", value: activeStateValue },
        });
    }

    return {
        handleGoToSelectBase,
        selectedTitle,
        setSelectedTitle,
        setStateSelect,
        setStateText,
    };
};
