export const useInboxListItem = ({
    inboxManager,
    message,
    refresh,
    selectedMessage,
    setSelectedMessage,
}) => {
    function handleDelete() {
        // Delete logic
        const isDeletingSelectedMessage = selectedMessage?.id === message.id;
        inboxManager.deleteMessage(message, {
            onStart: refresh,
            onSuccess: () => {
                inboxManager.fetchMessages({ onSuccess: refresh });
            },
        });
        isDeletingSelectedMessage && setSelectedMessage(null);
        refresh();
    }

    function handleSelect() {
        setSelectedMessage(message);
    }

    return { handleDelete, handleSelect };
};
