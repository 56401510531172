import React from "react";
import cx from "classnames";
import { Modal as ModalBootstrap } from "react-bootstrap";

// CSS
import "css/components/modals/modals.scss";

// Utils
import { handleKeyDown } from "../../utils/eventUtils";

export const Modal = (props) => {
    const {
        backdrop,
        bodyClasses,
        centered,
        children,
        className,
        contentClassName,
        cypressTestId,
        cypressCloseButtonTestId,
        dialogClassName,
        headerClasses,
        headerText,
        headerSubText,
        isHeaderTextCentered,
        shouldHideHeader,
        shouldHideHeaderBorder,
        onClose,
        onHide,
        show,
        size,
    } = props;

    function renderHeader() {
        return (
            !shouldHideHeader && (
                <ModalBootstrap.Header
                    className={cx("modal__header", {
                        "modal__header--borderless":
                            shouldHideHeaderBorder || !headerText,
                        "modal__header--centered": isHeaderTextCentered,
                        [headerClasses]: !!headerClasses,
                    })}
                >
                    <div className={cx("modal__header__text-wrapper")}>
                        <h2 className="headerText">{headerText}</h2>
                        {headerSubText && (
                            <p className="headerSubText">{headerSubText}</p>
                        )}
                    </div>
                    <span
                        className="modal__header__close material-icons"
                        data-cy={cypressCloseButtonTestId}
                        onClick={onClose || onHide}
                        tabIndex="0"
                        onKeyDown={(e) => handleKeyDown(e, onClose || onHide)}
                    >
                        close
                    </span>
                </ModalBootstrap.Header>
            )
        );
    }

    function renderBody() {
        return (
            <ModalBootstrap.Body
                className={cx("modal__body", {
                    [bodyClasses]: !!bodyClasses,
                })}
            >
                {children}
            </ModalBootstrap.Body>
        );
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <ModalBootstrap
                backdrop={backdrop}
                centered={!!centered ? centered.toString() : undefined}
                className={className}
                contentClassName={contentClassName}
                dialogClassName={cx(`${dialogClassName} modal__dialog`, {
                    "modal__dialog--default-size": !size,
                })}
                data-cy={cypressTestId}
                onHide={onHide}
                show={show}
                size={size}
            >
                {renderHeader()}
                {renderBody()}
            </ModalBootstrap>
        </div>
    );
};
