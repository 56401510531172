import React, { createContext, useContext, useState } from "react";

// Config
import { COMMUNITY_BASE_URL } from "config/circle";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

// Constants
const CommunityContext = createContext();

export function CommunityProvider({ children }) {
    /**
     * useState
     */

    const [bases, setBases] = useState([]);
    const [communityToken, setCommunityToken] = useState(null);
    const [hasLoadedBases, setHasLoadedBases] = useState(false);
    const [isLoadingBases, setIsLoadingBases] = useState(false);
    const [isLoadingCommunity, setIsLoadingCommunity] = useState(false);
    const [showCommunityModal, setShowCommunityModal] = useState(false);
    const [suggestedBase, setSuggestedBase] = useState(null);

    /**
     * End Hooks
     */

    async function fetchCommunitySpacesData() {
        try {
            setIsLoadingBases(true);
            const { json } = await SandboxxRestAPI.getCommunitySpaces();
            setBases(json.bases);
            setSuggestedBase(json.suggestedBase);
            setHasLoadedBases(true);
        } catch (err) {
        } finally {
            setIsLoadingBases(false);
        }
    }

    async function fetchCommunityAuthUrl() {
        try {
            setIsLoadingCommunity(true);
            if (communityToken) {
                return `${COMMUNITY_BASE_URL}/session/cookies?access_token=${communityToken}`;
            }
            const { json } = await SandboxxRestAPI.getCommunityToken();
            const token = json.access_token;
            setCommunityToken(token);
            return `${COMMUNITY_BASE_URL}/session/cookies?access_token=${token}`;
        } catch (err) {
            return COMMUNITY_BASE_URL;
        } finally {
            setIsLoadingCommunity(false);
        }
    }

    async function handleCommunityNavigation() {
        const url = await fetchCommunityAuthUrl();
        window.open(url, "_blank");
    }

    const communityContext = {
        bases,
        closeCommunityModal: () => setShowCommunityModal(false),
        fetchCommunitySpacesData,
        fetchCommunityAuthUrl,
        handleCommunityNavigation,
        isLoadingBases,
        isLoadingCommunity,
        openCommunityModal: () => {
            setShowCommunityModal(true);
            if (!hasLoadedBases) {
                fetchCommunitySpacesData();
            }
        },
        showCommunityModal,
        suggestedBase,
    };

    return (
        <CommunityContext.Provider value={communityContext}>
            {children}
        </CommunityContext.Provider>
    );
}

export function useCommunity() {
    const context = useContext(CommunityContext);
    if (context === undefined) {
        throw new Error("useCommunity must be used within a CommunityProvider");
    }
    return context;
}
