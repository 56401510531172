import React from "react";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./wallet-gift-cards-purchase-learn-more.module.scss";

export const WalletGiftCardsPurchaseLearnMore = (props) => {
    return (
        <div className={styles["wallet-gift-cards-purchase-learn-more"]}>
            <div className={styles.about}>
                <div className={styles.header}>
                    <Text type="h2">Learn More about Sandboxx Gift Cards</Text>
                </div>
                <div className={styles.body}>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            Give the gift of your support with Sandboxx Gift
                            Cards. The Sandboxx app helps you show your support
                            and stay connected with your loved ones during their
                            military journey with letters, gift cards,
                            motivational content, merchandise, and more. Giving
                            the gift of your support helps your loved ones worry
                            less about expenses and focus more on the task at
                            hand.
                        </li>
                        <li className={styles.item}>
                            Sandboxx Gift Cards are physical gift cards
                            delivered directly to your recruit by mail, so it's
                            there when they need it most.
                        </li>
                        <li className={styles.item}>
                            Requires no activation - it's ready to use once
                            received.
                        </li>
                        <li className={styles.item}>
                            Great for covering training expenses at on-base
                            exchanges or shopping anywhere Visa is accepted.{" "}
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles["terms-conditions"]}>
                <div className={styles.header}>
                    <Text type="h2">Terms & Conditions</Text>
                </div>
                <div className={styles.body}>
                    <Text classes={styles.text} type="p">
                        For more information on the Sandboxx Gift Card Terms and
                        Conditions, please click{" "}
                        <a
                            href="https://sandboxx.us/sandboxx-giftcard-terms-and-conditions"
                            rel="noreferrer"
                            target="_blank"
                        >
                            here
                        </a>
                        .
                    </Text>
                </div>
            </div>
        </div>
    );
};
