import { useMemo } from "react";

// Platforms
import { Iterable } from "platforms/iterable/iterable";

export const useDashboardAdsPreviewCard = ({ message }) => {
    /**
     * useMemo
     */

    const { elements, payload } = message || {};
    const { theme } = payload || {};
    const brandMarkUrl = payload?.images?.brandMark;
    const isSponsored = payload?.isSponsored;
    const buttonStyle = useMemo(
        () => ({
            backgroundColor: theme?.buttonBackgroundColor,
            color: theme?.buttonTextColor,
        }),
        [theme]
    );
    const cardStyle = useMemo(
        () => ({
            backgroundColor: theme?.backgroundColor,
            color: theme?.textColor,
        }),
        [theme]
    );
    const primaryButton = useMemo(() => elements?.buttons?.[0], [elements]);

    /**
     * End Hooks
     */

    const handleClick = () => {
        Iterable.trackEmbeddedMessageClickEvent({ message });
        if (primaryButton?.action?.data) {
            window.open(primaryButton.action.data, "_blank");
        }
    };

    return {
        buttonStyle,
        cardStyle,
        elements,
        handleClick,
        primaryButton,
        brandMarkUrl,
        isSponsored,
    };
};
