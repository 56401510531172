import React from "react";

// Components
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { DashboardTiles } from "../DashboardTiles/DashboardTiles";
import { DashboardTile } from "../DashboardTiles/components/DashboardTile/DashboardTile";

// Hooks
import { useDashboardGiftCardAndWeeklyUpdates } from "./useDashboardGiftCardAndWeeklyUpdates";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardGiftCardAndWeeklyUpdates = ({ weeklyUpdates }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { handleWeeklyUpdatesClick, handleGiftCardsClick } =
        useDashboardGiftCardAndWeeklyUpdates({ weeklyUpdates });

    /**
     * End Hooks
     */

    return (
        <DashboardTiles columns={2}>
            <DashboardTile
                icon={<CalendarMonthIcon />}
                iconWrapperBackgroundColor="var(--od-green-100)"
                onClick={handleWeeklyUpdatesClick}
                subtitle={t("weekly_updates.subtitle", { ns: "dashboard" })}
                title={t("weekly_updates.title", { ns: "dashboard" })}
            />
            <DashboardTile
                cypressTestId="dashboard-wallet-tile"
                icon={<CardGiftcardIcon />}
                iconWrapperBackgroundColor="var(--burnt-orange-100)"
                onClick={handleGiftCardsClick}
                subtitle={t("gift_cards.subtitle", { ns: "dashboard" })}
                title={t("gift_cards.title", { ns: "dashboard" })}
            />
        </DashboardTiles>
    );
};
