import React from "react";

// Components
import { DashboardHeaderStoryly } from "./components/DashboardHeaderStoryly/DashboardHeaderStoryly";
import { SandboxxPlusBadge } from "components/Badges/SandboxxPlusBadge/SandboxxPlusBadge";

// CSS
import styles from "./dashboard-header.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const DashboardHeader = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles.dashboardHeader}>
            <div className={styles.content}>
                <div className={styles.welcome}>
                    <span
                        className={styles.text}
                        data-cy="dashboard-header-welcome-text"
                    >
                        {t("header.welcome_back", {
                            firstName: CurrentUser.getUser()?.firstName,
                            ns: "dashboard",
                        })}
                    </span>
                    <SandboxxPlusBadge />
                </div>
                <DashboardHeaderStoryly />
            </div>
        </div>
    );
};
