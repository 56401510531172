// Enums
import { ImageAspectRatio } from "../enums/ImageAspectRatio";

/**
 * Generates an image blob and URL from an OffscreenCanvas instance
 *
 * @param {OffscreenCanvas} offscreenCanvas
 * @returns {Object} containing `blob` and `url` properties
 */
export async function generateBlobFromOffscreenCanvas(offscreenCanvas) {
    try {
        if (offscreenCanvas.width <= 0 || offscreenCanvas.height <= 0) {
            throw new Error("Invalid canvas dimensions");
        }
        const blob = await offscreenCanvas.convertToBlob({
            type: "image/jpeg",
        });
        const url = URL.createObjectURL(blob);
        return { blob, url };
    } catch (err) {
        throw err;
    }
}

/**
 * Generates dimensions for the initial centered crop window that is placed
 * over the user's images. The shape of the return object is built according
 * to the react-image-crop library documentation.
 *
 * https://github.com/DominicTobias/react-image-crop
 *
 * @param {Element} image
 * @returns {Object} containing crop specs
 */
export function generateInitialCrop(image, aspect) {
    const dimensions = generateInitialCropDimensions(image, aspect);
    return { ...dimensions, unit: "px", x: 0, y: 0 };
}

/**
 * Generates starting crop window dimensions so that the aspect ratio is 1
 * and each side of the crop is 50% of the image width or height (whichever is
 * smaller). If that can't be determined, it defaults to a crop window with
 * a side that equals 50% of the image width.
 *
 * @param {Element} image
 * @returns {Object} containing starting crop window dimensions (`width` and/or `height`)
 */
function generateInitialCropDimensions(image, aspect) {
    if (
        aspect === ImageAspectRatio.LETTERS ||
        aspect === ImageAspectRatio.PROFILE
    ) {
        if (image.width > image.height)
            return { height: image.height, width: image.height };
        if (image.height > image.width)
            return { width: image.width, height: image.width };
        if (image.width === image.height)
            return { width: image.width, height: image.height };
        return {};
    }
    if (aspect === ImageAspectRatio.SUPPORT_SQUAD) {
        return { width: image.width, height: (image.width / 455) * 142 };
    }
    return {};
}

/**
 * Generate an image blob that is resized to meet image upload server specs
 *
 * @param {Blob} blob
 * @param {String} url
 * @param {Object} config
 *  @param {Number} width of the final resized image in pixels
 * @returns {Promise} that resolves to a Blob or rejects to an Error
 */
export async function resizeFinalImage(blob, url, { width = 1200 } = {}) {
    return new Promise((resolve, reject) => {
        try {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                if (img.width <= width) {
                    resolve(blob);
                } else {
                    const canvas = document.createElement("canvas");
                    const aspectRatio = width / img.width;
                    canvas.width = width;
                    canvas.height = img.height * aspectRatio;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, img.height * aspectRatio);
                    ctx.canvas.toBlob((newBlob) => {
                        resolve(newBlob);
                    });
                }
            };
        } catch (err) {
            reject(err);
        }
    });
}
