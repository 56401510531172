import React from "react";

// Components
import { CommunityModalActions } from "./components/CommunityModalActions/CommunityModalActions";
import { CommunityModalFeatures } from "./components/CommunityModalFeatures/CommunityModalFeatures";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";

// CSS
import styles from "./community-modal.module.scss";

// Hooks
import { useCommunityModal } from "./useCommunityModal";
import { useTranslation } from "localization/localization";

export const CommunityModal = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();
    const {
        bases,
        closeCommunityModal,
        handleContinue,
        isLoadingBases,
        isLoadingCommunity,
        showCommunityModal,
        suggestedBase,
    } = useCommunityModal();

    /**
     * End Hooks
     */

    return (
        <Modal
            bodyClasses={styles.body}
            centered="true"
            className={styles.communityModal}
            contentClassName={styles.content}
            cypressTestId="community-modal"
            onClose={closeCommunityModal}
            onHide={closeCommunityModal}
            shouldHideHeader={true}
            show={showCommunityModal}
        >
            <div className={styles.header}>
                <Text type="h2" classes={styles.title}>
                    {t("community_modal.title", { ns: "components" })}
                </Text>
                <Text classes={styles.subtitle}>
                    {t("community_modal.subtitle", { ns: "components" })}
                </Text>
            </div>
            <CommunityModalFeatures />
            <CommunityModalActions
                bases={bases}
                isLoadingBases={isLoadingBases}
                isLoadingCommunity={isLoadingCommunity}
                onContinue={handleContinue}
                suggestedBase={suggestedBase}
            />
        </Modal>
    );
};
