import { useLayoutEffect, useRef } from "react";

// Config
import { storylyCategoryTokens } from "config/storyly";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const useStorylyGroup = ({ category, segments }) => {
    /**
     * useRef
     */

    const storylyRef = useRef();

    /**
     * useEffect
     */

    useLayoutEffect(() => {
        initializeStorylyContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storylyRef]);

    /**
     * End Hooks & Constants
     **/

    function initializeStorylyContent() {
        try {
            const token = storylyCategoryTokens[category];
            if (token) {
                storylyRef.current.init({
                    customParameter: CurrentUser.getUser().userId,
                    props: {
                        scrollIndicator: true,
                        storyGroupAlign: "left",
                        storyGroupSize: "64x64",
                    },
                    token,
                    segments: [...segments],
                });
            }
        } catch (err) {}
    }

    return {
        storylyRef,
    };
};
