import { useContext, useEffect, useMemo, useState } from "react";

// Context
import { HeaderBarContext } from "context/headerBar";
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { WalletNavigationEnum } from "../../../../../constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { isEmptyObject } from "utils/objectUtils";

// Constants
import { WalletListTabIds } from "../../../../../constants/constants";
const { MY_GIFT_CARDS } = WalletListTabIds;

export const useWalletManage = (props) => {
    const { setWalletCurrentSection } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [loading, setLoading] = useState({
        addCard: false,
        claimCard: false,
        purchaseCard: false,
        wallet: true,
    });
    const [purchasedGiftCards, setPurchasedGiftCards] = useState([]);
    const [selectedGiftCard, setSelectedGiftCard] = useState({});
    const [selectedWalletListTabId, setSelectedWalletListTabId] =
        useState(MY_GIFT_CARDS);
    const [shouldShowAddCardModal, setShouldShowAddCardModal] = useState(false);
    const [
        shouldShowAddCardBarcodeOverlay,
        setShouldShowAddCardBarcodeOverlay,
    ] = useState(false);
    const [shouldShowPurchaseCardModal, setShouldShowPurchaseCardModal] =
        useState(false);
    const [spendingGiftCards, setSpendingGiftCards] = useState([]);

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useEffect
     */

    useEffect(() => {
        // Set Header Bar Content
        const { firstName } = CurrentUser.getUser();
        setHeaderBarContent({
            backPathname: "",
            onBackClick: () =>
                setWalletCurrentSection(WalletNavigationEnum.PURCHASE),
            text: `${t("welcome_back", { ns: "common" })}, ${firstName}`,
            textSecondary: t("check_your_gift_card_balance_below", {
                ns: "wallet",
            }),
        });

        // Fetch Wallet
        fetchPurchasedGiftCards();
        fetchSpendingGiftCards();

        // Analytics
        AnalyticsLogger.logWalletTabClicked();
        AnalyticsLogger.logMyGiftCardTabViewed();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * useMemo
     */

    const hasSelectedGiftCard = useMemo(
        () => !isEmptyObject(selectedGiftCard),
        [selectedGiftCard]
    );

    /**
     * Constants
     */

    const hasSpendingGiftCardsInWallet = !!spendingGiftCards.length;
    const hasPurchasedGiftCardsInWallet = !!purchasedGiftCards.length;

    /**
     * End Hooks
     */

    async function addGiftCardToWalletWithBarcode(form) {
        try {
            setLoading((prev) => ({ ...prev, addCard: true }));
            const { json } =
                await SandboxxRestAPI.addGiftCardToWalletWithBarcode(form);
            onAddGiftCardToWalletWithBarcodeSuccess(json);
        } catch (err) {
            onAddGiftCardToWalletWithBarcodeFailure(err);
        }
    }

    async function fetchPurchasedGiftCards() {
        try {
            setLoading((prev) => ({ ...prev, wallet: true }));
            setSelectedGiftCard({});
            const { json } = await SandboxxRestAPI.getWalletGiftCards({
                ownershipType: "PURCHASE_NON_SPENDING",
            });
            onFetchPurchasedGiftCardsSuccess(json);
        } catch (err) {
            onFetchPurchasedGiftCardsFailure(err);
        }
    }

    async function fetchSpendingGiftCards() {
        try {
            setLoading((prev) => ({ ...prev, wallet: true }));
            setSelectedGiftCard({});
            const { json } = await SandboxxRestAPI.getWalletGiftCards({
                ownershipType: "ALL_SPENDING",
            });
            onFetchSpendingGiftCardsSuccess(json);
        } catch (err) {
            onFetchSpendingGiftCardsFailure(err);
        }
    }

    function handleSelectWalletListTab(tab) {
        setSelectedGiftCard({});
        setSelectedWalletListTabId(tab);
    }

    function onAddGiftCardToWalletWithBarcodeFailure(err) {
        const errorMessage = getErrorMessage(err);
        setLoading((prev) => ({ ...prev, addCard: false }));
        showNotification({ text: errorMessage, type: "warning" });
    }

    function onAddGiftCardToWalletWithBarcodeSuccess(res) {
        setLoading((prev) => ({ ...prev, addCard: false }));
        fetchSpendingGiftCards();
        setShouldShowAddCardModal(false);
        showNotification({
            text: t("add_card_barcode_success_message", { ns: "wallet" }),
            type: "success",
        });
    }

    function onFetchPurchasedGiftCardsFailure(err) {
        setLoading((prev) => ({ ...prev, wallet: false }));
    }

    function onFetchSpendingGiftCardsFailure(err) {
        setLoading((prev) => ({ ...prev, wallet: false }));
    }

    function onFetchPurchasedGiftCardsSuccess(res) {
        setPurchasedGiftCards(res);
        setLoading((prev) => ({ ...prev, wallet: false }));
    }

    function onFetchSpendingGiftCardsSuccess(res) {
        setSpendingGiftCards(res);
        setLoading((prev) => ({ ...prev, wallet: false }));
    }

    return {
        addGiftCardToWalletWithBarcode,
        fetchPurchasedGiftCards,
        fetchSpendingGiftCards,
        handleSelectWalletListTab,
        hasPurchasedGiftCardsInWallet,
        hasSelectedGiftCard,
        hasSpendingGiftCardsInWallet,
        loading,
        purchasedGiftCards,
        selectedGiftCard,
        selectedWalletListTabId,
        setSelectedGiftCard,
        shouldShowAddCardBarcodeOverlay,
        setShouldShowAddCardModal,
        setShouldShowPurchaseCardModal,
        setShouldShowAddCardBarcodeOverlay,
        shouldShowAddCardModal,
        shouldShowPurchaseCardModal,
        spendingGiftCards,
    };
};
