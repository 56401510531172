import { useEffect, useState } from "react";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { openImageInNewTab } from "utils/imageUtils";

export const useWalletDetail = ({ selectedGiftCard }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [barcodeImageSrc, setBarcodeImageSrc] = useState("");
    const [shouldHidePin, setShouldHidePin] = useState(true);

    /**
     * useEffect
     */

    useEffect(() => {
        setBarcodeImageSrc("");
        selectedGiftCard?.barcodeImageUrl && fetchBarcodeImage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGiftCard]);

    /**
     * Constants
     */

    const amountText =
        selectedGiftCard?.remainingBalance === null
            ? "0"
            : selectedGiftCard?.remainingBalance;
    const pinText = shouldHidePin ? "****" : selectedGiftCard?.pin;
    const showPinText = shouldHidePin
        ? t("show_pin", { ns: "wallet" })
        : t("hide_pin", { ns: "wallet" });

    /**
     * End Hooks
     */

    async function fetchBarcodeImage() {
        try {
            const json = await SandboxxRestAPI.getImageCloudfront(
                selectedGiftCard?.barcodeImageUrl
            );
            setBarcodeImageSrc(json);
        } catch (err) {}
    }

    function handlePrintBarcodeImage() {
        if (barcodeImageSrc) {
            openImageInNewTab(barcodeImageSrc);
        }
    }

    function handleToggleShouldHidePin() {
        setShouldHidePin((prev) => !prev);
    }

    return {
        amountText,
        barcodeImageSrc,
        handlePrintBarcodeImage,
        handleToggleShouldHidePin,
        pinText,
        shouldHidePin,
        showPinText,
    };
};
