import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import CircularProgress from "@mui/material/CircularProgress";
import { CommunityModalActionsSupportedBases } from "./components/CommunityModalActionsSupportedBases/CommunityModalActionsSupportedBases";
import { SelectGroup } from "components/Inputs/SelectGroup/SelectGroup";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./community-modal-actions.module.scss";

// Hooks
import { useCommunityModalActions } from "./useCommunityModalActions";
import { useTranslation } from "localization/localization";

// Utils
import { generateWelcomeText } from "./utils";

export const CommunityModalActions = ({
    bases,
    isLoadingBases,
    isLoadingCommunity,
    onContinue,
    suggestedBase,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();
    const {
        groupedBases,
        handleBaseChange,
        handleGoToSuggestedSpace,
        isSelectingBase,
        isSubmittingBase,
        selectedBase,
        setIsSelectingBase,
    } = useCommunityModalActions({ bases, onContinue, suggestedBase });

    /**
     * useMemo
     */

    if (isLoadingBases) {
        return (
            <div className={styles.communityModalActions}>
                <Text classes={styles.header}>
                    <Skeleton
                        className="skeleton"
                        height="16px"
                        width="300px"
                    />
                </Text>
                <Skeleton
                    className={cx(styles.button, "skeleton")}
                    height="42px"
                />
                <Text classes={styles.helper}>
                    <Skeleton
                        className="skeleton"
                        height="16px"
                        width="250px"
                    />
                </Text>
            </div>
        );
    }

    if (isSelectingBase || !suggestedBase) {
        return (
            <div
                data-cy="community-modal-actions-select"
                className={styles.communityModalActions}
            >
                <div className={styles.header}>
                    <Text classes={styles.text}>
                        {t("community_modal.select_base_prompt", {
                            ns: "components",
                        })}
                    </Text>
                </div>
                <div className={styles.selectContainer}>
                    <SelectGroup
                        className={styles.select}
                        disabled={isSubmittingBase}
                        displayEmpty
                        groups={groupedBases}
                        id="community-base-select"
                        renderValue={(selected) => {
                            if (!selected)
                                return t(
                                    "community_modal.select_base_dropdown_placeholder",
                                    { ns: "components" }
                                );
                            const base = bases.find((b) => b.id === selected);
                            return base?.name || selected;
                        }}
                        onChange={handleBaseChange}
                        value={selectedBase || ""}
                    />
                    {isSubmittingBase && (
                        <div className={styles.spinner}>
                            <CircularProgress size={24} />
                        </div>
                    )}
                </div>
                <CommunityModalActionsSupportedBases
                    groupedBases={groupedBases}
                />
            </div>
        );
    }

    return (
        <div
            data-cy="community-modal-actions-suggested"
            className={styles.communityModalActions}
        >
            <div className={styles.header}>
                <Text classes={styles.text}>
                    {generateWelcomeText(suggestedBase)}
                </Text>
            </div>
            <ButtonPrimary
                classes={styles.button}
                isLoading={isLoadingCommunity || isLoadingBases}
                onClick={handleGoToSuggestedSpace}
            >
                <Text>
                    {t("community_modal.continue_button", { ns: "components" })}
                </Text>
            </ButtonPrimary>
            <div className={styles.helper}>
                <Text classes={styles.text}>
                    {t("community_modal.different_base_prompt", {
                        ns: "components",
                    })}{" "}
                    <button
                        className={cx(styles.link, "link")}
                        onClick={() => setIsSelectingBase(true)}
                        type="button"
                    >
                        {t("community_modal.select_base_link", {
                            ns: "components",
                        })}
                    </button>
                </Text>
            </div>
        </div>
    );
};

CommunityModalActions.propTypes = {
    bases: PropTypes.array,
    isLoadingBases: PropTypes.bool,
    isLoadingCommunity: PropTypes.bool,
    onContinue: PropTypes.func,
    suggestedBase: PropTypes.object,
};
