import React, { useRef, useEffect } from "react";
import GoogleMap from "google-map-react";
import PropTypes from "prop-types";

// CSS
import "../../../css/components/google-map.scss";

// Constants
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const mapMarker = require("../../../media/icons/map-marker.svg").default;

export const TrackingMap = ({ checkpoints, zoom }) => {
    /**
     * useRef
     */

    const mapContainerRef = useRef(null);
    const mapInstanceRef = useRef(null);

    /**
     * useEffect
     */
    useEffect(() => {
        return () => {
            if (mapInstanceRef.current) {
                mapInstanceRef.current = null;
            }
        };
    }, []);

    /**
     * Constants
     */

    const mapCenter = [35.4675602, -97.516427599999];
    const mapKeys = {
        key: GOOGLE_MAP_API_KEY,
        libraries: "geometry",
    };
    const containerStyle = {
        position: "relative",
        width: "100%",
    };
    const mapStyle = {
        position: "absolute",
        width: "100%",
        height: "600px",
    };

    /**
     * End Hooks & Constants
     */

    async function apiIsLoaded(map, maps) {
        if (map && maps) {
            mapInstanceRef.current = map;
            generateMarkers(map, maps);
            generateFlightPath(map, maps);
        }
    }

    function generateMarkers(map, maps) {
        const bounds = new maps.LatLngBounds();
        const markers = checkpoints.map((checkpoint) => {
            const { latitude, longitude } = checkpoint;
            return new maps.Marker({
                animation: maps.Animation.DROP,
                icon: mapMarker,
                map,
                position: {
                    lat: latitude,
                    lng: longitude,
                },
            });
        });
        markers.forEach((marker) => {
            bounds.extend(marker.getPosition());
        });
        map.fitBounds(bounds);
    }

    async function generateFlightPath(map, maps) {
        if (!map || !maps) return;
        const path = checkpoints
            .sort((a, b) => a.scanDate - b.scanDate)
            .map(({ latitude, longitude }) => ({
                lat: latitude,
                lng: longitude,
            }));
        const flightBorder = new maps.Polyline({
            geodesic: true,
            path,
            strokeColor: "#ff5522",
            strokeOpacity: 0.8,
            strokeWeight: 5,
        });
        const flightPath = new maps.Polyline({
            geodesic: true,
            path,
            strokeColor: "#ff5522",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });
        flightBorder.setMap(map);
        flightPath.setMap(map);
    }

    return (
        <div ref={mapContainerRef} style={containerStyle}>
            <GoogleMap
                bootstrapURLKeys={mapKeys}
                center={mapCenter}
                options={createMapOptions}
                style={mapStyle}
                yesIWantToUseGoogleMapApiInternals
                zoom={zoom}
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            />
        </div>
    );
};

TrackingMap.propTypes = {
    checkpoints: PropTypes.arrayOf(
        PropTypes.shape({
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired,
            scanDate: PropTypes.string.isRequired,
        })
    ).isRequired,
    zoom: PropTypes.number,
};

TrackingMap.defaultProps = {
    zoom: 5,
};

function createMapOptions(maps) {
    return {
        directionsTravelMode: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT,
        },
        scrollwheel: false,
        zoomControlOptions: {
            position: maps.ControlPosition.TOP_LEFT,
            style: maps.ZoomControlStyle.SMALL,
        },
    };
}
