// Context
import { useCommunity } from "context/community";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const useCommunityModal = () => {
    /**
     * useContext
     */

    const {
        bases,
        closeCommunityModal,
        fetchCommunitySpacesData,
        handleCommunityNavigation,
        isLoadingBases,
        isLoadingCommunity,
        showCommunityModal,
        suggestedBase,
    } = useCommunity();

    /**
     * End Hooks
     */

    async function handleContinue() {
        try {
            AnalyticsLogger.logCommunityUserSelectedSuggestedBase();
            await handleCommunityNavigation();
        } finally {
            closeCommunityModal();
        }
    }

    return {
        bases,
        closeCommunityModal,
        fetchCommunitySpacesData,
        handleContinue,
        isLoadingBases,
        isLoadingCommunity,
        showCommunityModal,
        suggestedBase,
    };
};
