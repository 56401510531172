// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const useCommunityModalActionsSupportedBases = () => {
    function handlePopoverToggle(nextShow) {
        if (nextShow) {
            AnalyticsLogger.logCommunityUserSelectedViewSupportedBases();
        }
    }

    return { handlePopoverToggle };
};
