import CircularProgress from "@mui/material/CircularProgress";

export const LoadingIcon = ({ color }) => (
    <CircularProgress
        size={24}
        sx={{
            color:
                color === "#b3b3b3"
                    ? "var(--gray-dark)"
                    : "var(--blue-dark-background)",
        }}
    />
);
