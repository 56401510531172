import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// CSS
import styles from "./dashboard-tiles.module.scss";

export const DashboardTiles = ({ children, columns = 2, className }) => {
    return (
        <div
            className={cx(styles.dashboardTiles, className)}
            style={{
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
            }}
        >
            {children}
        </div>
    );
};

DashboardTiles.propTypes = {
    children: PropTypes.node.isRequired,
    columns: PropTypes.number,
    className: PropTypes.string,
};
