// Hooks
import { useHistory } from "react-router-dom";

export const useDashboardGiftCardAndWeeklyUpdates = ({ weeklyUpdates }) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    const handleWeeklyUpdatesClick = () => {
        if (weeklyUpdates) {
            history.push({
                pathname: "/weekly-updates",
                state: { weeklyUpdates },
            });
        } else {
            history.push("/weekly-updates");
        }
    };

    const handleGiftCardsClick = () => {
        history.push("/sandboxx-gift-card");
    };

    return {
        handleWeeklyUpdatesClick,
        handleGiftCardsClick,
    };
};
