import * as Sentry from "@sentry/react";

export function generateImagePath() {
    try {
        const uuid = crypto.randomUUID();
        const imagePath = "pic/" + uuid + ".jpg";
        return imagePath;
    } catch (err) {}
}

export function generateImageNameAndPath() {
    try {
        const imageName = crypto.randomUUID();
        const imagePath = "pic/" + imageName + ".jpg";
        return { imageName, imagePath };
    } catch (err) {}
}

export function selectImageFile(e, { onInit, onLoadEnd }) {
    e.persist();
    e.preventDefault();
    onInit?.();

    // Check if file was selected
    if (!e?.target?.files || e.target.files.length === 0) {
        return;
    }

    const file = e.target.files[0];

    // Validate file is a Blob/File
    if (!(file instanceof Blob)) {
        return;
    }

    // Initialize FileReader and callbacks
    const reader = new FileReader();
    reader.onerror = (error) => {
        Sentry.captureException(error);
    };
    reader.onloadend = () => {
        onLoadEnd?.(file, reader);
    };

    try {
        reader.readAsDataURL(file);
    } catch (error) {
        Sentry.captureException(error);
    }
}

export function openImageInNewTab(src) {
    // If there is no src present, exit the function
    if (!src) return;

    // Otherwise, open the image in a new tab using the src parameter
    const image = new Image();
    image.src = src;
    const newWindow = window.open("");
    newWindow.document.write(image.outerHTML);
}
