import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonSecondary, ButtonPrimary } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Text } from "components/Text/Text";

// CSS
import styles from "./wallet-manage-purchase-card-modal-recipient-details.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Validation
import { Validation } from "validation/validation";

// Constants
import { NavigationEnum, ProvidersEnum } from "../../constants/constants";
const { SELECT_DESIGN } = NavigationEnum;
const { AAFES, MCX, NEX } = ProvidersEnum;

export const WalletManagePurchaseCardModalRecipientDetails = ({
    fetchWalletGiftCardOptions,
    loading,
    purchaseInfo,
    setCurrentSection,
    setPurchaseInfo,
}) => {
    const { isForCurrentUser, provider } = purchaseInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [email, setEmail] = useState("");

    /**
     * End Hooks & Constants
     */

    function handleChangeInput({ target }) {
        setEmail(target.value);
    }

    function handleSelectGiftCardIsForCurrentUser(e) {
        e.preventDefault();
        setPurchaseInfo((prev) => ({
            ...prev,
            isForCurrentUser: !prev.isForCurrentUser,
        }));
    }

    function handleSubmitForm(e) {
        e.preventDefault();
        fetchWalletGiftCardOptions(provider);
        setPurchaseInfo((prev) => ({ ...prev, email }));
        setCurrentSection(SELECT_DESIGN);
    }

    function handleSelectProvider(e, provider) {
        e.preventDefault();
        setPurchaseInfo((prev) => ({
            ...prev,
            provider,
        }));
    }

    function validateEmail() {
        if (isForCurrentUser) {
            return true;
        } else {
            return Validation.isEmailAddress(email);
        }
    }

    return (
        <div data-cy="purchase-modal-recipient-details">
            <form className={styles.form}>
                <label className={styles.label} htmlFor="email">
                    <span className={styles.text}>Recipient Email</span>
                    <span className={styles["text-secondary"]}>
                        {t("please_enter_recipient_email", { ns: "wallet" })}
                    </span>
                </label>
                <input
                    // Connects input with react-hook-form
                    {...(isForCurrentUser && { disabled: true })}
                    className={cx(styles.input, "input")}
                    data-cy="recipient-email-input"
                    id="email"
                    onChange={handleChangeInput}
                    type="text"
                />
                <Checkbox
                    checked={isForCurrentUser}
                    cyId="giftcard-for-self-checkbox"
                    id="wallet-purchase-modal-recipient-details-is-for-current-user"
                    onChange={handleSelectGiftCardIsForCurrentUser}
                    text={t("this_gift_card_is_for_me", { ns: "wallet" })}
                />
                {isForCurrentUser && (
                    <Banner classes={styles.banner}>
                        <span>
                            {t("gift_card_redeem_exchange", { ns: "wallet" })}
                        </span>
                    </Banner>
                )}
                <label className={styles.label} htmlFor="branchId">
                    <span className={styles.text}>Recipient Branch</span>
                    <span className={styles["text-secondary"]}>
                        {t("which_exchange_access", { ns: "wallet" })}
                    </span>
                </label>
                <ButtonSecondary
                    classes={cx(styles.button)}
                    isFilled={provider === NEX}
                    onClick={(e) => handleSelectProvider(e, NEX)}
                >
                    <Text>{t("navy", { ns: "common" })}</Text>
                </ButtonSecondary>
                <ButtonSecondary
                    classes={cx(styles.button)}
                    cypressTestId="wallet-purchase-modal-marine-button"
                    isFilled={provider === MCX}
                    onClick={(e) => handleSelectProvider(e, MCX)}
                >
                    <Text>{t("marine_corps", { ns: "common" })}</Text>
                </ButtonSecondary>
                <ButtonSecondary
                    classes={cx(styles.button)}
                    isFilled={provider === AAFES}
                    onClick={(e) => handleSelectProvider(e, AAFES)}
                >
                    <Text>{t("army_air_force", { ns: "common" })}</Text>
                </ButtonSecondary>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="wallet-purchase-modal-recipient-details-continue-button"
                    isDisabled={!provider || !validateEmail()}
                    isLoading={loading.purchaseCard}
                    onClick={handleSubmitForm}
                    type="submit"
                >
                    <Text>{t("continue", { ns: "common" })}</Text>
                </ButtonPrimary>
            </form>
        </div>
    );
};

WalletManagePurchaseCardModalRecipientDetails.propTypes = {
    fetchWalletGiftCardOptions: PropTypes.func.isRequired,
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    purchaseInfo: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setPurchaseInfo: PropTypes.func.isRequired,
};
