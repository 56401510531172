import { useMemo, useState } from "react";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { generateBranchPrettyName } from "utils/userUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useCommunityModalActions = ({
    bases,
    onContinue,
    suggestedBase,
}) => {
    /**
     * useState
     */

    const [isSelectingBase, setIsSelectingBase] = useState(false);
    const [isSubmittingBase, setIsSubmittingBase] = useState(false);
    const [selectedBase, setSelectedBase] = useState("");

    /**
     * useMemo
     */

    const groupedBases = useMemo(() => {
        if (!bases?.length) return [];
        const basesByBranch = bases.reduce((acc, base) => {
            if (!acc[base.branch]) {
                acc[base.branch] = [];
            }
            acc[base.branch].push({
                value: base.id,
                label: base.name,
            });
            return acc;
        }, {});
        return Object.entries(basesByBranch)
            .map(([branch, options]) => ({
                label: generateBranchPrettyName(branch) || branch,
                options: options.sort((a, b) => a.label.localeCompare(b.label)),
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [bases]);

    /**
     * End Hooks
     */

    async function handleBaseChange(e) {
        const spaceId = e.target.value;
        setSelectedBase(spaceId);
        try {
            setIsSubmittingBase(true);
            await SandboxxRestAPI.setCommunitySpace({ spaceId });
            AnalyticsLogger.logCommunityUserSelectedManualBase();
            onContinue();
        } catch (error) {}
    }

    async function handleGoToSuggestedSpace() {
        setSelectedBase(suggestedBase.id);
        try {
            setIsSubmittingBase(true);
            await SandboxxRestAPI.setCommunitySpace({
                spaceId: suggestedBase.id,
            });
            AnalyticsLogger.logCommunityUserSelectedManualBase();
            onContinue();
        } catch (error) {}
    }

    return {
        groupedBases,
        handleBaseChange,
        handleGoToSuggestedSpace,
        isSelectingBase,
        isSubmittingBase,
        selectedBase,
        setIsSelectingBase,
    };
};
