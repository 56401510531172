import React from "react";

// Components
import { CarouselV2 } from "components/CarouselV2/CarouselV2";
import { DashboardAdsPreviewCard } from "./components/DashboardAdsPreviewCard/DashboardAdsPreviewCard";

// CSS
import styles from "./dashboard-ads.module.scss";

// Hooks
import { useDashboardAds } from "./hooks/useDashboardAds";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardAds = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();
    const { isLoadingEmbeddedMessages, messages } = useDashboardAds();

    /**
     * End Hooks
     */

    function renderLoading() {
        return new Array(2).fill(
            <DashboardAdsPreviewCard isLoading key={crypto.randomUUID()} />
        );
    }

    function renderMessages() {
        if (messages) {
            const messageItems = messages?.map((message) => (
                <DashboardAdsPreviewCard
                    isLoading={isLoadingEmbeddedMessages}
                    key={message?.metadata?.messageId}
                    message={message}
                />
            ));
            messageItems.push(
                <div key="placeholder" className={styles.placeholder} />
            );
            return messageItems;
        }
    }

    if (isLoadingEmbeddedMessages || (messages && messages.length > 0)) {
        return (
            <div className={styles.dashboardAds}>
                <CarouselV2
                    classNames={{
                        container: styles.carousel,
                        item: styles.item,
                        wrapper: "",
                    }}
                    gap={24}
                    maxItems={messages?.length || 0}
                    title={t("featured", { ns: "common" })}
                >
                    {isLoadingEmbeddedMessages
                        ? renderLoading()
                        : renderMessages()}
                </CarouselV2>
            </div>
        );
    }

    return null;
};
