import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./address-card.module.scss";

// Media
import sandboxxStampApprovalSrc from "media/shared/sandboxx-stamp-approval.png";

// Utils
import { generateFullName } from "utils/userUtils";

export const AddressCard = ({
    className,
    config,
    contact,
    dataCy,
    name,
    shouldReverseFullName,
}) => {
    const {
        city = "",
        line1 = "",
        line2 = "",
        state = "",
        zipcode = "",
    } = contact?.address || {};

    return (
        <Panel
            classes={cx(styles.addressCard, className)}
            cypressTestId={dataCy}
        >
            {!config.shouldHideStamp && (
                <img
                    alt="Sandboxx stamp of approval"
                    className={styles.stamp}
                    src={sandboxxStampApprovalSrc}
                />
            )}
            <Text classes={styles.name}>
                {name ? name : generateFullName(contact, shouldReverseFullName)}
            </Text>
            <Text classes={styles.line1}>{line1}</Text>
            <Text classes={styles.line2}>{line2}</Text>
            <Text classes={styles.city}>
                {city}, {state} {zipcode}
            </Text>
        </Panel>
    );
};

AddressCard.defaultProps = {
    className: "",
    config: {
        shouldHideStamp: false,
    },
    contact: {},
    dataCy: "",
    name: "",
    shouldReverseFullName: false,
};

AddressCard.propTypes = {
    className: PropTypes.string,
    config: PropTypes.shape({
        shouldHideStamp: PropTypes.bool,
    }),
    contact: PropTypes.shape({
        address: PropTypes.shape({
            city: PropTypes.string,
            line1: PropTypes.string,
            line2: PropTypes.string,
            state: PropTypes.string,
            zipcode: PropTypes.string,
        }),
    }),
    dataCy: PropTypes.string,
    name: PropTypes.string,
    shouldReverseFullName: PropTypes.bool,
};
