// Components
import { HomeIcon, HomeIconFilled } from "components/Icons/Navigation/HomeIcon";
import {
    InboxIcon,
    InboxIconFilled,
} from "components/Icons/Navigation/InboxIcon";
import {
    InviteIcon,
    InviteIconFilled,
} from "components/Icons/Navigation/InviteIcon";
import { LetterFilledIcon } from "components/Icons/LetterFilledIcon";
import { LetterIcon } from "components/Icons/LetterIcon";
import { LoadingIcon } from "../components/LoadingIcon/LoadingIcon";
import {
    MusterIcon,
    MusterIconFilled,
} from "components/Icons/Navigation/MusterIcon";
import { NewsIcon, NewsIconFilled } from "components/Icons/Navigation/NewsIcon";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import {
    ProfileIcon,
    ProfileIconFilled,
} from "components/Icons/Navigation/ProfileIcon";
import ShieldIcon from "@mui/icons-material/Shield";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import {
    WalletIcon,
    WalletIconFilled,
} from "components/Icons/Navigation/WalletIcon";

// Enums
import { NavBarItemKeys } from "../enums/NavBarItemKeys";
import { PersonaEnum } from "enums/PersonaEnum";

// Localization
import { t } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

/**
 * This method generates the dynamic parameters for the letters navigation item.
 *
 * @param {String} rootPersona The users root persona
 * @returns {Object} containing data that defines how the Letters NavBar UI should render
 */
export function generateLettersDynamicParams(rootPersona = {}) {
    const isRecruit = rootPersona.id === PersonaEnum.RECRUIT;
    const isRecruiter = rootPersona.id === PersonaEnum.RECRUITER;
    const isRecruiterCommand = rootPersona.id === PersonaEnum.RECRUITER_COMMAND;
    const hasMilitaryPersona = isRecruit || isRecruiter || isRecruiterCommand;
    return {
        imageAlt: hasMilitaryPersona
            ? t("letter_icon", { ns: "common" })
            : t("home_icon", { ns: "common" }),
        ImageSrc: hasMilitaryPersona ? LetterIcon : HomeIcon,
        ImageSrcSelected: hasMilitaryPersona
            ? LetterFilledIcon
            : HomeIconFilled,
        text: hasMilitaryPersona
            ? t("letter_ip_plural", { ns: "common" })
            : t("home", { ns: "common" }),
    };
}

export function generateMusterDynamicParams(rootPersona) {
    const isRecruit = rootPersona.id === PersonaEnum.RECRUIT;
    const isRecruiter = rootPersona.id === PersonaEnum.RECRUITER;
    const isRecruiterCommand = rootPersona.id === PersonaEnum.RECRUITER_COMMAND;
    const hasMilitaryPersona = isRecruit || isRecruiter || isRecruiterCommand;
    return {
        imageAlt: hasMilitaryPersona
            ? t("home_icon", { ns: "common" })
            : t("waypoints_icon", { ns: "common" }),
        ImageSrc: hasMilitaryPersona ? HomeIcon : MusterIcon,
        ImageSrcSelected: hasMilitaryPersona
            ? HomeIconFilled
            : MusterIconFilled,
        text: hasMilitaryPersona
            ? t("home", { ns: "common" })
            : t("waypoints", { ns: "common" }),
    };
}

/**
 * This method generates a data object for each potential item in the
 * final list. This prevents us from duplicating items for each role.
 *
 * @param {Object} dynamicParams containing dynamic data that impacts the NavBar UI
 * @returns {Object} containing data that defines how the NavBar UI should render
 */
function generateNavBarItemData(dynamicParams, account) {
    const {
        COMMUNITY,
        INBOX,
        LETTERS,
        MUSTER,
        NEWS,
        PROFILE,
        REFERRALS,
        SQUADS,
        WALLET,
    } = NavBarItemKeys;
    const keywords = generateNavBarItemKeywords(account);
    const hasCommunityBase = account?.user?.communitySpaceGroups?.[0];

    return {
        [COMMUNITY]: {
            ...dynamicParams[COMMUNITY],
            dataCy: "navbar-community-link",
            key: COMMUNITY,
            imageAlt: t("community", { ns: "common" }),
            ImageSrc: dynamicParams[COMMUNITY]?.isLoading
                ? LoadingIcon
                : PeopleOutlineIcon,
            ImageSrcSelected: dynamicParams[COMMUNITY]?.isLoading
                ? LoadingIcon
                : PeopleIcon,
            text: t("community", { ns: "common" }),
            onClick: hasCommunityBase
                ? dynamicParams[COMMUNITY]?.handleCommunityNavigation
                : dynamicParams[COMMUNITY]?.onClick,
        },
        [INBOX]: {
            ...dynamicParams[INBOX],
            href: "/inbox",
            imageAlt: t("inbox_icon", { ns: "common" }),
            ImageSrc: InboxIcon,
            ImageSrcSelected: InboxIconFilled,
            isInternalLink: true,
            key: INBOX,
            keywords: keywords[INBOX],
            text: t("inbox", { ns: "common" }),
        },
        [LETTERS]: {
            ...dynamicParams[LETTERS],
            href: "/letters",
            isInternalLink: true,
            key: LETTERS,
            keywords: keywords[LETTERS],
        },
        [MUSTER]: {
            ...dynamicParams[MUSTER],
            href: "/waypoints",
            isInternalLink: true,
            key: MUSTER,
            keywords: keywords[MUSTER],
        },
        [NEWS]: {
            ...dynamicParams[NEWS],
            href: "https://www.sandboxx.us/news/?utm_source=webapp&utm_medium=navigation&utm_campaign=news",
            imageAlt: t("news_icon", { ns: "common" }),
            ImageSrc: NewsIcon,
            ImageSrcSelected: NewsIconFilled,
            isInternalLink: false,
            key: NEWS,
            keywords: keywords[NEWS],
            onClick: AnalyticsLogger.logNewsFeedViewed,
            text: t("news", { ns: "common" }),
        },
        [PROFILE]: {
            ...dynamicParams[PROFILE],
            href: "/profile",
            imageAlt: t("profile_icon", { ns: "common" }),
            ImageSrc: ProfileIcon,
            ImageSrcSelected: ProfileIconFilled,
            isInternalLink: true,
            key: PROFILE,
            keywords: keywords[PROFILE],
            text: t("profile", { ns: "common" }),
        },
        [SQUADS]: {
            ...dynamicParams[SQUADS],
            href: "/support-squad",
            imageAlt: t("squads_icon", { ns: "common" }),
            ImageSrc: ShieldOutlinedIcon,
            ImageSrcSelected: ShieldIcon,
            isInternalLink: true,
            key: SQUADS,
            keywords: keywords[SQUADS],
            text: t("squad_ip_plural", { ns: "common" }),
        },
        [REFERRALS]: {
            ...dynamicParams[REFERRALS],
            href: "/letters/referrals",
            imageAlt: "Invite icon",
            ImageSrc: InviteIcon,
            ImageSrcSelected: InviteIconFilled,
            isInternalLink: true,
            key: REFERRALS,
            keywords: ["/letters/referrals"],
            text: "Invite",
        },
        [WALLET]: {
            ...dynamicParams[WALLET],
            href: "/sandboxx-gift-card",
            imageAlt: t("wallet_icon", { ns: "common" }),
            ImageSrc: WalletIcon,
            ImageSrcSelected: WalletIconFilled,
            isInternalLink: true,
            key: WALLET,
            keywords: keywords[WALLET],
            text: t("gift_card_ip_plural", { ns: "common" }),
            textStyle: { transform: "translateY(2px)" },
        },
    };
}

// This method constructs the final array of nav item data
export function generateNavBarItemDataList(
    rootPersona = { id: "UNKNOWN" },
    dynamicParams,
    account
) {
    const navBarItemsData = generateNavBarItemData(dynamicParams, account);
    switch (true) {
        case PersonaEnum.RECRUITER === rootPersona.id:
        case PersonaEnum.RECRUITER_COMMAND === rootPersona.id:
            return [
                navBarItemsData.MUSTER,
                navBarItemsData.LETTERS,
                navBarItemsData.SQUADS,
                navBarItemsData.COMMUNITY,
                navBarItemsData.NEWS,
                navBarItemsData.INBOX,
                navBarItemsData.WALLET,
                navBarItemsData.PROFILE,
            ];
        case PersonaEnum.RECRUIT === rootPersona.id:
            return [
                navBarItemsData.MUSTER,
                navBarItemsData.LETTERS,
                navBarItemsData.SQUADS,
                navBarItemsData.COMMUNITY,
                navBarItemsData.NEWS,
                navBarItemsData.INBOX,
                navBarItemsData.WALLET,
                navBarItemsData.PROFILE,
            ];
        case PersonaEnum.LETTER_WRITER === rootPersona.id:
        default:
            return [
                navBarItemsData.LETTERS,
                navBarItemsData.REFERRALS,
                navBarItemsData.SQUADS,
                navBarItemsData.COMMUNITY,
                navBarItemsData.NEWS,
                navBarItemsData.INBOX,
                navBarItemsData.WALLET,
                navBarItemsData.PROFILE,
            ];
    }
}

export function generateNavBarItemKeywords(account) {
    const rootPersona = account?.user?.personas[0] || "UNKNOWN";
    return {
        [NavBarItemKeys.COMMUNITY]: [],
        [NavBarItemKeys.INBOX]: ["/inbox", "/inbox/"],
        [NavBarItemKeys.LETTERS]: [
            "/bundles",
            "/bundles/",
            "/letters",
            "/letters/",
            "/letters/bundles",
            "/letters/bundles/",
            "/letters/compose",
            "/letters/compose/",
            "/letters/confirmation",
            "/letters/confirmation/",
            "/letters/feed",
            "/letters/feed/",
            "/letters/sent",
            "/letters/sent/",
            /**
             * The Letters nav item should NOT be active when the following
             * personas visit the "/" route
             */
            rootPersona.id === PersonaEnum.RECRUITER ||
            rootPersona.id === PersonaEnum.RECRUIT ||
            rootPersona.id === PersonaEnum.RECRUITER_COMMAND
                ? null
                : "/",
        ],
        [NavBarItemKeys.MUSTER]: [
            "/muster",
            "/muster/",
            "/muster/articles",
            "/muster/articles/",
            "/muster/overview",
            "/muster/overview/",
            "/waypoints",
            "/waypoints/",
            "/waypoints/articles",
            "/waypoints/articles/",
            "/waypoints/overview",
            "/waypoints/overview/",
            /**
             * The Waypoints nav item should NOT be active when the following
             * personas visit the "/" route
             */
            rootPersona.id === PersonaEnum.RECRUITER ||
            rootPersona.id === PersonaEnum.RECRUIT
                ? "/"
                : null,
        ],
        [NavBarItemKeys.NEWS]: [],
        [NavBarItemKeys.PROFILE]: ["/profile", "/profile/"],
        [NavBarItemKeys.REFERRALS]: [
            "/letters/referrals",
            "/letters/referrals/",
            "/letters/referral-program",
            "/letters/referral-program/",
        ],
        [NavBarItemKeys.SQUADS]: ["/support-squad", "/support-squad/"],
        [NavBarItemKeys.WALLET]: [
            "/sandboxx-gift-card",
            "/sandboxx-gift-card/",
        ],
    };
}
