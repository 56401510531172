import { useContext, useEffect, useState } from "react";

// Context
import { HeaderBarContext } from "context/headerBar";
import { NotificationBarContext } from "context/notificationBar";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useReferrals = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [earnedLetters, setEarnedLetters] = useState(null);
    const [isLoadingRewardsStatus, setIsLoadingRewardsStatus] = useState(true);
    const [pendingLetters, setPendingLetters] = useState(null);
    const [pendingReferrals, setPendingReferrals] = useState(null);
    const [referredReferrals, setReferredReferrals] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        // Initialize
        setHeaderBarContent({
            backPathname: null,
            onBackClick: null,
            text: t("invite", { ns: "common" }),
        });

        // Fetch rewards
        AnalyticsLogger.logReferralFeedViewed();
        fetchRewards();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    async function fetchRewards() {
        try {
            const { json } = await SandboxxRestAPI.getReferralRewards();
            onFetchRewardsSuccess(json);
        } catch (err) {
            onFetchRewardsFailure(err);
        }
    }

    function onFetchRewardsFailure(err) {
        setIsLoadingRewardsStatus(false);
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onFetchRewardsSuccess({
        earned_credits,
        pending_credits,
        users_pending,
        users_referred,
    }) {
        setEarnedLetters(earned_credits);
        setIsLoadingRewardsStatus(false);
        setPendingLetters(pending_credits);
        setPendingReferrals(users_pending);
        setReferredReferrals(users_referred);
    }

    return {
        earnedLetters,
        isLoadingRewardsStatus,
        pendingLetters,
        pendingReferrals,
        referredReferrals,
    };
};
