// Platforms
import { Analytics } from "platforms/analytics";
import { Intercom } from "platforms/intercom";

export class AnalyticsLogger {
    /**
     * Account Actions
     */

    static logDeleteAccountConfirm() {
        Analytics.trackEvent("firebase", {
            event: "delete_account_confirm",
        });
    }

    static logDeleteAccountError() {
        Analytics.trackEvent("firebase", {
            event: "delete_account_error",
        });
    }

    static logDeleteAccountProfileButtonClick() {
        Analytics.trackEvent("firebase", {
            event: "delete_account_start",
        });
    }

    static logDeleteAccountSuccess() {
        Analytics.trackEvent("firebase", {
            event: "delete_account_end",
        });
        Analytics.trackEvent("iterable", {
            event: "delete_account",
        });
    }

    /**
     * Community
     */

    static logCommunityUserSelectedManualBase() {
        Analytics.trackEvent("firebase", {
            event: "community_user_selected_manual_base",
        });
    }

    static logCommunityUserSelectedSuggestedBase() {
        Analytics.trackEvent("firebase", {
            event: "community_user_selected_suggested_base",
        });
    }

    static logCommunityUserSelectedViewSupportedBases() {
        Analytics.trackEvent("firebase", {
            event: "community_user_selected_view_supported_bases",
        });
    }

    /**
     * Purchase
     */

    static logAddAddOnToCart(addOn) {
        const { productDetails, productType, title } = addOn;
        const { price } = productDetails;
        const priceFormatted = parseFloat(price);
        Analytics.trackEventEcommerce("firebase", "addToCart", {
            itemCategory: "Newsletter",
            itemId: title,
            itemName: title,
            items: [
                {
                    itemCategory: productType,
                    itemId: title,
                    itemName: title,
                    price: priceFormatted,
                },
            ],
            value: priceFormatted,
        });
    }

    static logAddGiftCardToCart(giftCard, base) {
        const { selected } = giftCard;
        const { fee, provider } = selected;
        const feeCalculated = base * (fee / 100);
        const itemId = `${provider} Gift Card`;
        const itemName = `${base} Gift Card`;
        Analytics.trackEventEcommerce("firebase", "addToCart", {
            itemCategory: "Gift Card",
            itemId,
            itemName,
            items: [
                {
                    itemCategory: "Gift Card",
                    itemId,
                    itemName,
                    price: feeCalculated,
                },
            ],
            value: feeCalculated,
        });
    }

    // TODO: Rename to "add_payment_info_failed"
    static logAddPaymentInfoFail() {
        Analytics.trackEvent("iterable", {
            event: "add_payment_info_fail",
        });
    }

    static logAddPaymentInfoInitiated() {
        Analytics.trackEvent("iterable", {
            event: "add_payment_info_initiated",
        });
    }

    static logAddPaymentInfoSuccess() {
        Analytics.trackEvent("iterable", {
            event: "add_payment_info_success",
        });
    }

    static logBeginCheckoutAddOn(addOn) {
        const { productDetails, productType, title } = addOn;
        const { price } = productDetails;
        const value = parseFloat(price);
        Analytics.trackEventEcommerce("firebase", "beginCheckout", {
            currency: "USD",
            itemCategory: productType,
            itemId: title,
            itemName: title,
            items: [
                {
                    itemId: title,
                    itemName: title,
                    itemCategory: productType,
                    price: value,
                    quantity: 1,
                },
            ],
            total_spend: value,
            value,
        });
    }

    static logBeginCheckoutBundle({ bundle }) {
        const { id, title, total } = bundle;
        const value = parseFloat(total.substring(1));
        const total_spend = parseFloat(total.substring(1));
        Analytics.trackEventEcommerce("firebase", "beginCheckout", {
            currency: "USD",
            itemCategory: "Letter Bundle",
            itemId: id,
            itemName: title,
            items: [
                {
                    itemId: id,
                    itemName: title,
                    itemCategory: "Letter Bundle",
                    price: value,
                    quantity: 1,
                },
            ],
            total_spend,
            value,
        });
    }

    static logBeginCheckoutGiftCard(giftCard) {
        const { amount, selected } = giftCard;
        const { base, fee, total } = amount;
        const { provider } = selected;
        const itemId = `${provider} Gift Card`;
        const itemName = `${base} Gift Card`;
        Analytics.trackEventEcommerce("firebase", "beginCheckout", {
            currency: "USD",
            itemCategory: "Gift Card",
            itemId,
            itemName,
            items: [
                {
                    itemId,
                    itemName,
                    itemCategory: "Gift Card",
                    price: fee,
                    quantity: 1,
                },
            ],
            total_spend: total,
            value: fee,
        });
    }

    static logGiftCardEnd() {
        Analytics.trackEvent("iterable", { event: "gift_card_end" });
    }

    static logGiftCardStart() {
        Analytics.trackEvent("iterable", { event: "gift_card_start" });
    }

    static logPromoCodeFailure({ discount }) {
        const { code } = discount;
        Analytics.trackEvent("iterable", {
            event: "promo_code_failure",
            data: { promoCode: code },
        });
    }

    static logPromoCodeSuccess({ discount, discountedBundle }) {
        const { code } = discount;
        const { promotion, title } = discountedBundle;
        Analytics.trackEvent("iterable", {
            event: "promo_code_success",
            data: {
                item: title,
                promoCode: code,
                promoCodeValue: promotion.percentage,
            },
        });
    }

    static logPurchaseAddOns(selectedAddOns) {
        // Handle Iterable which batches all add-ons in one single event
        Analytics.trackPurchase("iterable", {
            items: selectedAddOns.map((addOn) => {
                const { productDetails, title } = addOn;
                const { price, productKey } = productDetails;
                return {
                    id: productKey,
                    name: title,
                    price: parseFloat(price),
                    quantity: 1,
                };
            }),
            total: selectedAddOns.reduce(
                (acc, curr) => acc + parseFloat(curr.productDetails.price),
                0.0
            ),
        });
        // Handle Firebase which fires off an event for each add-on
        selectedAddOns.forEach(({ productDetails, title }) => {
            const { price } = productDetails;
            const value = parseFloat(price);
            Analytics.trackEventEcommerce("firebase", "purchase", {
                currency: "USD",
                itemCategory: "Newsletter",
                itemId: title,
                itemName: title,
                items: [
                    {
                        itemCategory: "Newsletter",
                        itemId: title,
                        itemName: title,
                        price: value,
                    },
                ],
                total_spend: value,
                value,
            });
        });
    }

    static logBundleSelectOvernightShipping() {
        Analytics.trackEvent("firebase", {
            event: "bundle_select_overnight_shipping",
        });
    }

    static logBundleSelectUSPSVerifiedAddress() {
        Analytics.trackEvent("firebase", {
            event: "bundle_select_usps_verified_address",
        });
    }

    static logBundleToggleBasic() {
        Analytics.trackEvent("firebase", {
            event: "bundle_toggle_basic",
        });
    }

    static logBundleTogglePlus() {
        Analytics.trackEvent("firebase", {
            event: "bundle_toggle_plus",
        });
    }

    static logBundleView() {
        Analytics.trackEvent("firebase", {
            event: "bundle_view",
        });
    }

    static logPurchaseBundle(bundle, discount) {
        const { id, price, tax, title, total } = bundle;
        const promoIsActive = discount.status === "active";
        const coupon = promoIsActive ? { coupon: discount.code } : {};
        const value = parseFloat(price.substring(1));
        const valueFormatted = price.replace(/\$/g, "");
        const taxAmount = parseFloat(tax.taxAmount.substring(1));
        const totalSpend = parseFloat(total.substring(1));
        Analytics.trackEventEcommerce("firebase", "purchase", {
            ...coupon,
            currency: "USD",
            itemCategory: "Letter Bundle",
            itemId: id,
            itemName: title,
            items: [
                {
                    itemCategory: "Letter Bundle",
                    itemId: id,
                    itemName: title,
                    price: value,
                    quantity: 1,
                },
            ],
            tax: taxAmount,
            total_spend: totalSpend,
            value,
        });
        Analytics.trackEvent("gtm", {
            adrollConversionValue: valueFormatted,
            adrollOrderId: id,
            event: "bundlePurchaseAdroll",
        });
        Analytics.trackEvent("gtm", {
            bundlePurchaseFacebook: {
                currency: "USD",
                value: valueFormatted,
            },
            event: "bundlePurchaseFacebook",
        });
        Analytics.trackEvent("gtm", {
            bundlePurchase: price,
            event: "bundlePurchase",
        });
        Analytics.trackEvent("firebase", {
            data: { currency: "USD", value: price },
            event: "bundlePurchase",
        });
        Analytics.trackPurchase("iterable", {
            items: [
                {
                    id: id.toString(),
                    name: title,
                    price: value,
                    quantity: 1,
                },
            ],
            total: totalSpend,
        });
        if (promoIsActive) {
            Analytics.trackEvent("firebase", { event: "promo_code_success" });
        }
    }

    static logPurchaseGiftCard(giftCard) {
        const { amount, selected } = giftCard;
        const { base, fee, total } = amount;
        const { provider } = selected;
        const itemId = `${provider} Gift Card`;
        const itemName = `${base} Gift Card`;
        Analytics.trackEventEcommerce("firebase", "purchase", {
            currency: "USD",
            itemCategory: "Gift Card",
            itemId,
            itemName,
            items: [
                {
                    itemCategory: "Gift Card",
                    itemId,
                    itemName,
                    price: fee,
                },
            ],
            total_spend: total,
            value: fee,
        });
        Analytics.trackPurchase("iterable", {
            items: [
                {
                    id: base.toString(),
                    name: "Gift Card",
                    price: base,
                    quantity: 1,
                },
            ],
            total,
        });
    }

    static logRemoveAddOnFromCart(addOn) {
        const { productDetails, productType, title } = addOn;
        const { price } = productDetails;
        const priceFormatted = parseFloat(price);
        Analytics.trackEventEcommerce("firebase", "removeFromCart", {
            itemCategory: productType,
            itemId: title,
            itemName: title,
            items: [
                {
                    itemCategory: productType,
                    itemId: title,
                    itemName: title,
                    price: priceFormatted,
                },
            ],
            value: priceFormatted,
        });
    }

    static logRemoveGiftCardFromCart(giftCard) {
        const { amount, selected } = giftCard;
        const { base, fee } = amount;
        const { provider } = selected;
        const itemId = `${provider} Gift Card`;
        const itemName = `${base} Gift Card`;
        Analytics.trackEventEcommerce("firebase", "removeFromCart", {
            itemCategory: "Gift Card",
            itemId,
            itemName,
            items: [
                {
                    itemCategory: "Gift Card",
                    itemId,
                    itemName,
                    price: fee,
                },
            ],
            value: fee,
        });
    }

    static logPromoCodeStart() {
        Analytics.trackEvent("firebase", { event: "promo_code_start" });
        Analytics.trackEvent("iterable", { event: "promo_code_start" });
    }

    /**
     * Bundles
     */

    static logAddBundleToCart({ bundle }) {
        const { id, price, title } = bundle;
        const priceFormatted = parseFloat(price.substring(1));
        Analytics.trackEventEcommerce("firebase", "addToCart", {
            itemCategory: "Letter Bundle",
            itemId: id,
            itemName: title,
            items: [
                {
                    itemCategory: "Letter Bundle",
                    itemId: id,
                    itemName: title,
                    price: priceFormatted,
                },
            ],
            value: priceFormatted,
        });
    }

    /**
     * Contact
     */

    static logContactDelete() {
        Analytics.trackEvent("firebase", { event: "contact_delete" });
        Analytics.trackEvent("iterable", { event: "contact_delete" });
    }

    static logContactSave(isWritingLetter) {
        Analytics.trackEvent("firebase", {
            event: "contact_save",
            data: {
                source: isWritingLetter ? "letter_composition" : "my_profile",
            },
        });
        Analytics.trackEvent("iterable", {
            event: "contact_save",
        });
    }

    static logContactSearch() {
        Analytics.trackEvent("firebase", { event: "contact_search" });
        Analytics.trackEvent("iterable", { event: "contact_search" });
    }

    static logContactView() {
        Analytics.trackEvent("firebase", { event: "contact_view" });
        Analytics.trackEvent("iterable", { event: "contact_view" });
    }

    static logEditContactView() {
        Analytics.trackEvent("firebase", {
            event: "edit_contact_view",
        });
        Analytics.trackEvent("iterable", {
            event: "edit_contact_view",
        });
    }

    /**
     * Dashboard
     */

    static logHomeView() {
        Analytics.trackEvent("iterable", {
            event: "home_view",
        });
    }

    /**
     * Letter
     */

    static logEditLetter() {
        Analytics.trackEvent("iterable", {
            event: "edit_letter",
        });
    }

    static logEditLetterSave() {
        Analytics.trackEvent("iterable", {
            event: "edit_letter_save",
        });
    }

    static logLetterComposeAddNewRecipient() {
        Analytics.trackEvent("firebase", {
            event: "letter_compose_add_new_recipient",
        });
        Analytics.trackEvent("iterable", {
            event: "letter_compose_add_new_recipient",
        });
    }

    static logLetterComposeAddPhoto() {
        Analytics.trackEvent("firebase", { event: "letter_compose_add_photo" });
        Analytics.trackEvent("iterable", { event: "letter_compose_add_photo" });
    }

    static logUnlockMultiplePhotosButtonClicked() {
        Analytics.trackEvent("firebase", {
            event: "unlock_multiple_photos",
        });
    }

    static logLetterComposeConfirmation() {
        Analytics.trackEvent("firebase", {
            event: "letter_compose_confirmation",
        });
    }

    static logLetterComposeEnd({ recipient }) {
        const { firstName, fullName, id, lastName } = recipient;
        Analytics.trackEvent("firebase", { event: "letter_compose_end" });
        Analytics.trackEvent("iterable", {
            event: "letter_compose_end",
            data: {
                recipient: {
                    recruitFirstName: firstName,
                    recruitFullName: fullName,
                    recruitLastName: lastName,
                    userID: id,
                },
            },
        });
    }

    static logLetterComposeReviewViewed() {
        Analytics.trackEvent("iterable", {
            event: "letter_compose_review_viewed",
        });
    }

    static logLetterComposeSelectRecipient() {
        Analytics.trackEvent("firebase", {
            event: "letter_compose_select_recipient",
        });
        Analytics.trackEvent("iterable", {
            event: "letter_compose_select_recipient",
        });
    }

    static logLetterComposeStart() {
        Analytics.trackEvent("firebase", { event: "letter_compose_start" });
        Analytics.trackEvent("iterable", { event: "letter_compose_start" });
    }

    static logLetterComposeSubmitMessage() {
        Analytics.trackEvent("firebase", {
            event: "letter_compose_submit_message",
        });
        Analytics.trackEvent("iterable", {
            event: "letter_compose_submit_message",
        });
    }

    static logLetterComposeSaveDraft() {
        Analytics.trackEvent("firebase", {
            event: "manual_save_draft",
        });
    }

    /**
     * Magic Link
     */

    static logMagicLinkContinueClicked() {
        Analytics.trackEvent("firebase", {
            event: "magiclink_continue_clicked",
        });
        Analytics.trackEvent("iterable", {
            event: "magiclink_continue_clicked",
        });
    }

    static logMagicLinkCopied() {
        Analytics.trackEvent("firebase", { event: "copy_invite_link" });
        Analytics.trackEvent("iterable", { event: "copy_invite_link" });
    }

    static logMagicLinkInviteMore() {
        Analytics.trackEvent("firebase", {
            event: "invite_more_clicked",
        });
        Analytics.trackEvent("iterable", {
            event: "invite_more_clicked",
        });
    }

    static logMagicLinkShared() {
        Analytics.trackEvent("firebase", {
            event: "share_invite_link_clicked",
        });
        Analytics.trackEvent("iterable", {
            event: "share_invite_link_clicked",
        });
    }

    /**
     * Muster
     */

    static logMusterDashboardViewed() {
        Analytics.trackEvent("firebase", { event: "muster_dashboard_viewed" });
        Analytics.trackEvent("iterable", { event: "muster_dashboard_viewed" });
    }

    // Needs data
    static logMusterInvitationSent(email) {
        Analytics.trackEvent("firebase", { event: "muster_invitation_sent" });
        Analytics.trackEvent("iterable", {
            event: "muster_invitation_sent",
            data: { referral: { email } },
        });
    }

    static logMusterJoined() {
        Analytics.trackEvent("firebase", { event: "muster_joined" });
        Analytics.trackEvent("iterable", { event: "muster_joined" });
    }

    static logMusterStartSession() {
        Analytics.trackEvent("firebase", { event: "muster_session" });
        Analytics.trackEvent("iterable", { event: "muster_session" });
    }

    /**
     * News
     */

    static logNewsArticleViewed({ article }) {
        const { author, title, tags, url } = article;
        Analytics.trackEvent("iterable", {
            event: "news_article_viewed",
            data: {
                articleTitle: title,
                articleUrl: url,
                author: author.name,
                tags,
            },
        });
    }

    static logNewsFeedViewed() {
        Analytics.trackEvent("iterable", { event: "news_feed_viewed" });
    }

    /**
     * Phone Verification
     */

    static logVerificationConfirmPhone() {
        Analytics.trackEvent("firebase", {
            event: "verification_confirm_phone",
        });
    }

    /**
     * TODO: Move this to `logVerificationConfirmPhone` when
     * TODO: un-auth'd Iterable event tracking is possible
     */
    static logVerificationConfirmPhoneIterable() {
        Analytics.trackEvent("iterable", {
            event: "verification_confirm_phone",
        });
    }

    static logVerificationEnterPhone() {
        Analytics.trackEvent("firebase", {
            event: "verification_enter_phone",
        });
        Analytics.trackEvent("iterable", {
            event: "verification_enter_phone",
        });
    }

    /**
     * Referrals
     */

    static logReferralFeedViewed() {
        Analytics.trackEvent("firebase", { event: "referral_feed_viewed" });
    }

    /**
     * Sign Up
     */

    static logOnboardingEnd() {
        Analytics.trackEvent("firebase", { event: "onboarding_end" });
        Analytics.trackEvent("iterable", { event: "onboarding_end" });
    }

    static logOnboardingStart() {
        Analytics.trackEvent("firebase", { event: "onboarding_start" });
        Analytics.trackEvent("iterable", { event: "onboarding_start" });
    }

    static logSignUpEnd() {
        Analytics.trackEvent("firebase", { event: `sign_up_end` });
    }

    /**
     * TODO: Move this to `logSignUpEnd` when
     * TODO: un-auth'd Iterable event tracking is possible
     */
    static logSignUpEndIterable() {
        Analytics.trackEvent("iterable", { event: "sign_up_end" });
    }

    /**
     * Support Squad
     */

    static logSquadDonateTokens() {
        Analytics.trackEvent("firebase", { event: "squad_donate_tokens_cta" });
        Intercom.trackEvent("squad_donate_tokens_cta");
    }

    static logSquadDonateTokensNumber(numberOfTokens) {
        Analytics.trackEvent("firebase", {
            event: "squad_token_donated_value",
            data: { value: numberOfTokens },
        });
        Analytics.trackEvent("iterable", {
            event: "squad_token_donated",
            data: { value: numberOfTokens },
        });
        Intercom.trackEvent("squad_token_donated_value", {
            value: numberOfTokens,
        });
    }

    static logSquadViewed() {
        Analytics.trackEvent("firebase", { event: "squad_viewed" });
        Analytics.trackEvent("iterable", { event: "squad_viewed" });
        Intercom.trackEvent("squad_viewed");
    }

    static logSquadGroupsViewed() {
        Analytics.trackEvent("firebase", { event: "squad_groups_viewed" });
        Analytics.trackEvent("iterable", { event: "squad_groups_viewed" });
        Intercom.trackEvent("squad_groups_viewed");
    }
    static logSquadInviteJoined() {
        Analytics.trackEvent("firebase", { event: "squad_invite_joined" });
        Analytics.trackEvent("iterable", { event: "squad_invite_joined" });
        Intercom.trackEvent("squad_invite_joined");
    }

    static logSquadSendLetter() {
        Analytics.trackEvent("firebase", { event: "squad_send_letter_cta" });
        Analytics.trackEvent("iterable", { event: "squad_send_letter_cta" });
        Intercom.trackEvent("squad_send_letter_cta");
    }

    /**
     * Support Squad Admin
     */

    static logSquadCreated({ squad }) {
        const { squadId, squadName } = squad;
        Analytics.trackEvent("firebase", { event: "squad_created" });
        Analytics.trackEvent("iterable", {
            event: "squad_created",
            data: { squadID: squadId, squadName },
        });
    }

    static logSquadDeleted({ squad }) {
        const { squadId, squadName } = squad;
        Analytics.trackEvent("firebase", { event: "squad_deleted" });
        Analytics.trackEvent("iterable", {
            event: "squad_deleted",
            data: { squadID: squadId, squadName },
        });
        Intercom.trackEvent("squad_deleted");
    }

    static logSquadInviteSent({ referrals, squad }) {
        const { squadId, squadName } = squad;
        Analytics.trackEvent("firebase", { event: "squad_invite_sent" });
        Analytics.trackEvent("iterable", {
            event: "squad_invite_sent",
            data: { referrals, squadID: squadId, squadName },
        });
    }

    static logSquadInviteSupportersSelected() {
        Analytics.trackEvent("firebase", {
            event: "invite_supporters_selected",
        });
    }

    static logSquadManageSquadSelected() {
        Analytics.trackEvent("firebase", { event: "manage_squad_selected" });
    }

    static logSquadMemberDeleted({ member, squad }) {
        const { memberFullName, memberId } = member;
        const { squadId, squadName } = squad;
        Analytics.trackEvent("firebase", { event: "squad_member_deleted" });
        Analytics.trackEvent("iterable", {
            event: "squad_member_deleted",
            data: {
                deletedUser: { fullName: memberFullName, userID: memberId },
                squadID: squadId,
                squadName,
            },
        });
    }

    static logSquadUpdateRecipientAddressEnd() {
        Analytics.trackEvent("firebase", {
            event: "update_recipient_address_end",
        });
    }

    static logSquadUpdateRecipientAddressSelected() {
        Analytics.trackEvent("firebase", {
            event: "update_recipient_address_selected",
        });
    }

    static logSquadUpdateSquadDetailsSelected() {
        Analytics.trackEvent("firebase", {
            event: "update_squad_details_selected",
        });
    }

    static logSquadViewMembersSelected() {
        Analytics.trackEvent("firebase", {
            event: "view_members_selected",
        });
    }

    /**
     * Wallet
     */

    // Digital Wallet v1.1 (Legacy)

    static logMyGiftCardTabViewed() {
        Analytics.trackEvent("firebase", {
            event: "my_gift_card_tab_viewed",
        });
    }

    static logSentGiftCardTabViewed() {
        Analytics.trackEvent("firebase", {
            event: "sent_gift_card_tab_viewed",
        });
    }

    // Digital Wallet v1 (Legacy)

    static logWalletTabClicked() {
        Analytics.trackEvent("firebase", {
            event: "wallet_tab_clicked",
        });
    }

    /**
     * Weekly Updates / Dep List
     */

    static logDepArticleClicked({ article, branch }) {
        const { tags, title } = article;
        Analytics.trackEvent("firebase", { event: "dep_article_clicked" });
        Analytics.trackEvent("iterable", {
            event: "dep_article_clicked",
            data: { articleTitle: title, branch, tags },
        });
    }

    static logDepCategoryClicked() {
        Analytics.trackEvent("firebase", { event: "dep_category_clicked" });
        Analytics.trackEvent("iterable", { event: "dep_category_clicked" });
    }

    static logDepListViewed() {
        Analytics.trackEvent("firebase", { event: "dep_list_viewed" });
        Analytics.trackEvent("iterable", { event: "dep_list_viewed" });
    }

    static logWeeklyUpdateCTAClicked() {
        Analytics.trackEvent("iterable", {
            event: "weekly_update_cta_clicked",
        });
    }

    static logWeeklyUpdateOpened() {
        Analytics.trackEvent("iterable", { event: "weekly_update_opened" });
    }

    static logWeeklyUpdatesViewed() {
        Analytics.trackEvent("iterable", {
            event: "weekly_updates_viewed",
        });
    }
}
