import React from "react";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@material-ui/lab";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-ads-preview-card.module.scss";

// Hooks
import { useDashboardAdsPreviewCard } from "./hooks/useDashboardAdsPreviewCard";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const DashboardAdsPreviewCard = ({ isLoading, message }) => {
    /**
     * Custom Hooks
     */

    const {
        brandMarkUrl,
        buttonStyle,
        cardStyle,
        elements,
        handleClick,
        primaryButton,
        isSponsored,
    } = useDashboardAdsPreviewCard({ message });

    /**
     * End Hooks
     */

    if (isLoading) {
        return (
            <div className={styles.dashboardAdsPreviewCard}>
                <Skeleton variant="rect" width="100%" height="240px" />
            </div>
        );
    }

    return (
        <div
            className={styles.dashboardAdsPreviewCard}
            onClick={handleClick}
            onKeyDown={(e) => handleKeyDown(e, handleClick)}
            role="button"
            style={cardStyle}
            tabIndex={0}
        >
            <div className={styles.content}>
                <Text classes={styles.title}>{elements.title}</Text>
                <Text classes={styles.body}>{elements.body}</Text>
                <div className={styles.footer}>
                    {primaryButton && (
                        <button className={styles.button} style={buttonStyle}>
                            {primaryButton.title}
                        </button>
                    )}
                    {isSponsored && (
                        <Text
                            classes={styles.sponsored}
                            style={{ color: cardStyle.color }}
                        >
                            Sponsored
                        </Text>
                    )}
                </div>
            </div>
            <div className={styles.imageWrapper}>
                <img
                    alt={elements.title}
                    className={styles.image}
                    src={elements.mediaUrl}
                />
                {brandMarkUrl && (
                    <img
                        alt="Brand Mark"
                        className={styles.brandMark}
                        src={brandMarkUrl}
                    />
                )}
            </div>
        </div>
    );
};

DashboardAdsPreviewCard.propTypes = {
    isLoading: PropTypes.bool,
    message: PropTypes.shape({
        elements: PropTypes.shape({
            body: PropTypes.string,
            buttons: PropTypes.arrayOf(
                PropTypes.shape({
                    action: PropTypes.shape({
                        type: PropTypes.string,
                    }),
                    title: PropTypes.string,
                })
            ),
            mediaUrl: PropTypes.string,
            title: PropTypes.string,
        }),
        payload: PropTypes.shape({
            theme: PropTypes.shape({
                backgroundColor: PropTypes.string,
                buttonBackgroundColor: PropTypes.string,
                buttonTextColor: PropTypes.string,
                textColor: PropTypes.string,
            }),
            images: PropTypes.shape({
                brandMark: PropTypes.string,
            }),
        }),
    }),
};
