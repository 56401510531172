import React, { useContext, useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { DateTime } from "luxon";

// Components
import { DashboardHeader } from "./components/DashboardHeader/DashboardHeader";
import { DashboardMain } from "./components/DashboardMain/DashboardMain";
import { GraduationModal } from "components/Graduation/GraduationModal";

// Context
import { AuthContext } from "context/auth/auth";
import { BundlesContext } from "context/bundles";
import { useCommunity } from "context/community";
import { CreditsContext } from "context/credits";
import { IterableContext } from "context/iterable/iterable";
import { NavBarContext } from "context/navbar";
import { NotificationBarContext } from "context/notificationBar";
import { PurchaseContext } from "context/purchase";

// CSS
import "css/dashboard/dashboard.scss";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

// Localization
import { useTranslation } from "localization/localization";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { SentLetter } from "models/letters/SentLetter";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "../../utils/sandboxx";
import { scrollToTop } from "../../utils/scrollUtils";

const Dashboard = (props) => {
    const { hasDepsShortCode, history, isUsingBundlesRoute } = props;
    const location = useLocation();
    const { openCommunityModal } = useCommunity();

    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    useDocumentTitle(t("document_title", { ns: "dashboard" }));

    /**
     * useContext
     **/

    const { account, fetchAccount } = useContext(AuthContext);
    const { fetchBundles } = useContext(BundlesContext);
    const { credits, fetchCredits } = useContext(CreditsContext);
    const { fetchEmbeddedMessages } = useContext(IterableContext);
    const { runShouldShowNavBarCheck } = useContext(NavBarContext);
    const { showNotification } = useContext(NotificationBarContext);
    const {
        fetchRecentPaymentMethod,
        fetchSavedCards,
        handlePreloadDiscount,
        setShowPurchaseModal,
    } = useContext(PurchaseContext);

    /**
     * useState
     **/

    const [activeDraft, setActiveDraft] = useState(null);
    const [connections, setConnections] = useState(null);
    const [depLocation, setDepLocation] = useState(null);
    const [display, setDisplay] = useState({
        showGraduationModal: false,
        showMore: false,
    });
    const [graduation, setGraduation] = useState(null);
    const [letterCutOffTime, setLetterCutOffTime] = useState(null);
    const [lettersSent, setLettersSent] = useState(null);
    const [lettersSentCursor, setLettersSentCursor] = useState("");
    const [loading, setLoading] = useState({
        dashboard: true,
        letters: true,
    });
    const [promotion, setPromotion] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [weeklyUpdates, setWeeklyUpdates] = useState({
        graduation: null,
        selected: null,
        updates: null,
    });

    /**
     * useEffect
     **/

    useEffect(() => {
        if (location.pathname === "/community") {
            openCommunityModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        runShouldShowNavBarCheck();
        scrollToTop();
        fetchAccount();
        fetchBundles({ recipientUserId: "", zipCode: "" });
        fetchCredits();
        fetchDashboard();
        fetchEmbeddedMessages();
        fetchGraduation();
        fetchKinConnections();
        fetchLettersSent();
        fetchRecentPaymentMethod();
        fetchSavedCards();
        AnalyticsLogger.logHomeView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (account?.user?.depLocation) {
            setDepLocation(account.user.depLocation);
        }
    }, [account]);

    useEffect(() => {
        if (hasDepsShortCode) {
            setDepsLocation();
        }
    }, [hasDepsShortCode]);

    useEffect(() => {
        if (isUsingBundlesRoute) {
            setShowPurchaseModal(isUsingBundlesRoute);
        }
    }, [isUsingBundlesRoute, setShowPurchaseModal]);

    useEffect(() => {
        showLastLetterSentNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lettersSent]);

    /**
     * Constants
     **/

    const { showGraduationModal } = display;

    /**
     * End Hooks
     **/

    async function fetchDashboard() {
        try {
            const { json } = await SandboxxRestAPI.getDashboard();
            setActiveDraft(json.activeDraft);
            setGraduation(json.graduation);
            setLetterCutOffTime(json.letterCutOffTime);
            setPromotion(json.promotion);
            setPromotions(json.promoCarousel);
            setLoading((prev) => ({ ...prev, dashboard: false }));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            showNotification({
                text: errorMessage,
                type: "warning",
            });
        }
    }

    async function fetchKinConnections() {
        try {
            const { json } = await SandboxxRestAPI.getKinConnections();
            const connections = json.map((connection) => {
                return { ...connection, connected: true };
            });
            setLoading((prev) => ({
                ...prev,
                connections: false,
            }));
            setConnections(connections);
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            showNotification({
                text: errorMessage,
                type: "warning",
            });
        }
    }

    async function fetchGraduation() {
        try {
            const { json } = await SandboxxRestAPI.getGraduation();
            setWeeklyUpdates((prev) => ({ ...prev, json }));
        } catch (err) {}
    }

    async function fetchLettersSent() {
        try {
            const { json } = await SandboxxRestAPI.getLettersSent(
                lettersSentCursor
            );
            const lettersSentInstances = json.response.map(
                (letter) => new SentLetter(letter)
            );
            const updatedList = lettersSentCursor
                ? lettersSent.concat(lettersSentInstances)
                : lettersSentInstances;
            setLettersSent(updatedList);
            setLoading((prev) => ({
                ...prev,
                letters: false,
            }));
            setLettersSentCursor(json.cursor);
            showLastLetterSentNotification();
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            showNotification({
                text: errorMessage,
                type: "warning",
            });
        }
    }

    function handleGraduationModalToggle() {
        setDisplay((prevDisplay) => ({
            ...prevDisplay,
            showGraduationModal: !prevDisplay.showGraduationModal,
        }));
    }

    function handlePurchaseModalToggle(discountCode) {
        discountCode &&
            typeof discountCode === "string" &&
            handlePreloadDiscount(discountCode);
        setShowPurchaseModal((prev) => !prev);
    }

    async function setDepsLocation() {
        const depsShortCode = localStorage.getItem("deps_short_code");
        try {
            await SandboxxRestAPI.setDepLocation({
                depsShortCode,
            });
        } catch (err) {}
    }

    function showLastLetterSentNotification() {
        const lastLetter = lettersSent && lettersSent[0];
        if (lastLetter) {
            const days = calculateDaysSinceLastLetter(lastLetter);
            if (days >= 5) {
                showNotification({
                    text: t("last_letter_sent", {
                        count: days,
                        ns: "notifications",
                    }),
                    type: "info",
                });
            }
        }
    }

    function renderContent() {
        return (
            <>
                <DashboardHeader />
                <DashboardMain
                    activeDraft={activeDraft}
                    connections={connections}
                    credits={credits}
                    depLocation={depLocation}
                    display={display}
                    fetchSentLetters={fetchLettersSent}
                    graduation={graduation}
                    handleGraduationModalToggle={handleGraduationModalToggle}
                    handlePurchaseModalToggle={handlePurchaseModalToggle}
                    history={history}
                    letterCutOffTime={letterCutOffTime}
                    lettersSent={lettersSent}
                    loading={loading}
                    lettersSentCursor={lettersSentCursor}
                    promotion={promotion}
                    promotions={promotions}
                    weeklyUpdates={weeklyUpdates}
                />
            </>
        );
    }

    return (
        <div className="page">
            <GraduationModal
                fetchDashboard={fetchDashboard}
                graduation={graduation}
                onHide={handleGraduationModalToggle}
                show={showGraduationModal}
                showNotification={showNotification}
            />
            {renderContent()}
        </div>
    );
};

// withRouter
export const WrappedDashboard = withRouter(Dashboard);

function calculateDaysSinceLastLetter(letter) {
    const { created_at } = letter;
    const { days } = DateTime.local()
        .diff(DateTime.fromISO(created_at), ["days", "hours", "minutes"])
        .toObject();
    return Math.floor(days);
}
