import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Fuse from "fuse.js";

// Context
import { ContactsContext } from "context/contacts";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

// Hooks
import { useDebounceValue } from "hooks/debounce/useDebounceValue";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const useProfileAddressBook = () => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const {
        contacts,
        loading,
        setOnSubmitContactSuccessFinalCallback,
        toggleContactModal,
    } = useContext(ContactsContext);

    /**
     * useState
     */

    const [fuse, setFuse] = useState(
        new Fuse(contacts, { keys: ["firstName", "fullName", "lastName"] })
    );
    const [hasSelectedSearch, setHasSelectedSearch] = useState(false);
    const [results, setResults] = useState([]);
    const [term, setTerm] = useState("");

    /**
     * useDebounce
     */

    const debouncedSearchTerm = useDebounceValue(term, 250);

    /**
     * useEffect
     */

    useEffect(() => {
        if (contacts && contacts.length) {
            setFuse(
                new Fuse(contacts, {
                    keys: ["firstName", "fullName", "lastName"],
                    threshold: 0.3,
                })
            );
            setResults(contacts);
        }
    }, [contacts]);

    useEffect(() => {
        if (!debouncedSearchTerm) {
            setResults(contacts);
        } else {
            setResults(
                fuse.search(debouncedSearchTerm).map(({ item }) => item)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    /**
     * The `hasSelectedSearch` flag is currently only used to determine
     * whether the `contact_search` analytics event should fire off or not
     */
    useEffect(() => {
        if (hasSelectedSearch) {
            AnalyticsLogger.logContactSearch();
        }
    }, [hasSelectedSearch]);

    /**
     * Constants
     */

    const isAddressBookEmpty =
        !loading.contacts && (!contacts || !contacts.length);

    /**
     * End Hooks
     */

    function handleSearchInputChange(e) {
        const { value } = e.target;
        setHasSelectedSearch(true);
        setTerm(value);
    }

    function handleToggleContactModal() {
        toggleContactModal({
            contactToEdit: {
                firstName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            isSupportSquad: false,
            isWritingLetter: false,
            shouldShow: true,
            targetScreen: PersonModalNavigation.NAME,
            type: "create",
        });
        setOnSubmitContactSuccessFinalCallback(() => onCreateContactSuccess);
    }

    function onCreateContactSuccess(contact) {
        history.replace(`/profile`);
        history.push(`/contact/${contact.abContactId}`, {
            contact,
        });
    }

    return {
        debouncedSearchTerm,
        handleSearchInputChange,
        handleToggleContactModal,
        isAddressBookEmpty,
        loading,
        results,
    };
};
