import React from "react";
import PropTypes from "prop-types";

// CSS
import styles from "./dashboard-tile.module.scss";

export const DashboardTile = ({
    cypressTestId,
    icon,
    title,
    subtitle,
    onClick,
    iconWrapperBackgroundColor,
}) => {
    const iconWrapperStyle = {
        backgroundColor: iconWrapperBackgroundColor,
    };

    return (
        <div
            className={styles.dashboardTile}
            data-cy={cypressTestId}
            onClick={onClick}
        >
            <div className={styles.iconWrapper} style={iconWrapperStyle}>
                {React.cloneElement(icon)}
            </div>
            <div className={styles.content}>
                <span className={styles.title}>{title}</span>
                <span className={styles.subtitle}>{subtitle}</span>
            </div>
        </div>
    );
};

DashboardTile.propTypes = {
    cypressTestId: PropTypes.string,
    icon: PropTypes.node.isRequired,
    iconWrapperBackgroundColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

DashboardTile.defaultProps = {
    iconWrapperBackgroundColor: "var(--pacific-blue-20)",
};
