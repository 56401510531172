import { useContext, useEffect, useState } from "react";

// Contexts
import { AuthContext } from "context/auth/auth";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Models
import { Persona } from "models/persona/Persona";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const usePersonaWizard = ({ setCurrentSection, setUserInfo }) => {
    /**
     * useContext
     */

    const { fetchAccount, isAuthenticated } = useContext(AuthContext);

    /**
     * useState
     */

    const [childPersonas, setChildPersonas] = useState([]);
    const [isLoadingRootPersonaSelection, setIsLoadingRootPersonaSelection] =
        useState(false);
    const [isLoadingChildPersonas, setIsLoadingChildPersonas] = useState(false);
    const [rootPersonas, setRootPersonas] = useState(null);
    const [selectedRootPersona, setSelectedRootPersona] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        if (isAuthenticated && !rootPersonas) {
            fetchRootPersonas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, rootPersonas]);

    /**
     * End Hooks
     */

    async function fetchChildPersonas(param) {
        try {
            const id = param.id;
            setIsLoadingChildPersonas(true);
            const { json } = await SandboxxRestAPI.getChildPersonas({ id });
            onFetchChildPersonasSuccess(json);
        } catch (err) {
            onFetchChildPersonasFailure(err);
        }
    }

    async function fetchRootPersonas() {
        try {
            const { json } = await SandboxxRestAPI.getRootPersonas();
            const personaInstances = json.personas.map(
                (persona) => new Persona(persona)
            );
            setRootPersonas(personaInstances);
        } catch (err) {}
    }

    function handleSelectChildPersona(persona, { onComplete }) {
        // If the selected child persona has children, fetch those
        // Otherwise, trigger onComplete callback
        if (persona.hasChildren()) {
            setIsLoadingChildPersonas(true);
            fetchChildPersonas(persona);
        } else {
            onComplete && onComplete();
        }
        // In either scenario, we want to tell the server what persona the user selected
        updatePersona(persona);
    }

    function handleSelectProfileChildPersona(persona, { onComplete }) {
        // If the selected child persona has children, fetch those and
        // trigger onComplete callback
        if (persona.hasChildren()) {
            setIsLoadingChildPersonas(true);
            fetchChildPersonas(persona);
            updatePersona(persona);
        } else {
            updatePersona(persona, onComplete);
        }
    }

    async function handleGoBackToPersonaSelection() {
        const json = await fetchAccount();
        const { user } = json;

        // Remove last persona
        user.personas.pop();

        // Create new mapping with remaing personas
        const newPersonas = user.personas.map((persona) => {
            return { id: persona.id };
        });

        // Update Sign Up User Info
        const userInfoPersonas = user.personas.map((persona) => {
            return persona.id;
        });

        setUserInfo?.((prev) => ({
            ...prev,
            personas: [...userInfoPersonas],
        }));

        /**
         * Update Personas and then fetch the child personas of the last
         * in the array
         */
        try {
            await SandboxxRestAPI.updatePersona(newPersonas);
            fetchChildPersonas(newPersonas[newPersonas.length - 1]);
        } catch (err) {}

        /**
         * Switch back to SignUpPersonaRoot if newPersonas is empty,
         * otherwise stay on the SignUpPersonaChild component
         */

        if (newPersonas.length === 0) {
            setCurrentSection?.(NavigationEnum.SIGN_UP_PERSONA_ROOT);
        } else {
            setCurrentSection?.(NavigationEnum.SIGN_UP_PERSONA_CHILD);
        }
    }

    async function handleSelectRootPersona(persona, callbacks) {
        setIsLoadingRootPersonaSelection(true);
        setIsLoadingChildPersonas(true);

        setUserInfo?.((prev) => ({
            ...prev,
            personas: [],
        }));

        try {
            await SandboxxRestAPI.updatePersona([{ id: persona.id }]);
            fetchAccount();
            try {
                const { json } = await SandboxxRestAPI.getChildPersonas({
                    id: persona.id,
                });
                onFetchChildPersonasSuccess(json);
                callbacks?.onSuccess && callbacks.onSuccess();
            } catch (err) {
                onFetchChildPersonasFailure(err);
            }
        } catch (err) {
            onUpdatePersonaFailure(err);
        }

        setSelectedRootPersona(persona);
    }

    function onFetchChildPersonasFailure(err) {
        setIsLoadingChildPersonas(false);
    }

    function onFetchChildPersonasSuccess({ personas }) {
        const personaInstances = personas.map(
            (persona) => new Persona(persona)
        );
        setChildPersonas((prev) => prev.concat([personaInstances]));
        setIsLoadingChildPersonas(false);
    }

    async function updatePersona(persona, onComplete) {
        const json = await fetchAccount();
        const { personas } = json.user;
        const personaIds = personas.map((persona) => {
            return { id: persona.id };
        });

        try {
            const { json } = await SandboxxRestAPI.updatePersona([
                ...personaIds,
                { id: persona.id },
            ]);
            onUpdatePersonaSuccess(json, onComplete);
        } catch (err) {
            onUpdatePersonaFailure(err);
        }
    }

    function onUpdatePersonaFailure(err) {}

    function onUpdatePersonaSuccess(res, onComplete = emptyFunction) {
        fetchAccount();
        onComplete();
    }

    return {
        childPersonas,
        fetchAccount,
        fetchChildPersonas,
        fetchRootPersonas,
        isLoadingRootPersonaSelection,
        handleGoBackToPersonaSelection,
        handleSelectChildPersona,
        handleSelectProfileChildPersona,
        handleSelectRootPersona,
        isLoadingChildPersonas,
        rootPersonas,
        selectedRootPersona,
        setIsLoadingChildPersonas,
        setIsLoadingRootPersonaSelection,
    };
};
