import { useTranslation } from "localization/localization";

export const useDashboardReferrals = ({ connections }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const connection = connections && connections[0];
    const bodyText = connection
        ? t("referrals.get_more_letters_to", {
              firstName: connection.firstName,
              ns: "dashboard",
          })
        : t("referrals.give_a_letter", {
              ns: "dashboard",
          });
    const linkToParams = {
        pathname: "/letters/referrals",
        state: {
            referringRecruit: {
                id: connection?.id || null,
                name: connection?.fullName || null,
            },
        },
    };

    /**
     * End Hooks & Constants
     */

    return {
        bodyText,
        linkToParams,
    };
};
