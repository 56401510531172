import React, { useEffect } from "react";

// Components
import { AddPaymentOption } from "components/PaymentOption/AddPaymentOption";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { SavedPaymentOptions } from "components/PaymentOption/SavedPaymentOptions";
import { Text } from "components/Text/Text";

// Localization
import { useTranslation } from "localization/localization";

export const LettersComposeAddOnsSelectPayment = ({
    changeCurrentSection,
    fetchSavedCards,
    loading,
    handleCloseModal,
    newCard,
    savedCards,
    selectedCard,
    setLoading,
    setNewCard,
    setSelectedCard,
    showNotification,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useEffect
     */

    useEffect(() => {
        fetchSavedCards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleCardInputChange(e) {
        const { name, value } = e.target;
        setNewCard({
            ...newCard,
            [name]: value,
        });
    }

    function handleCardSelect(selectedCard, isSavedCard) {
        setSelectedCard((prev) => ({
            ...prev,
            card: selectedCard,
            isSavedCard,
        }));
    }

    function handleCardSetToken(token) {
        setNewCard({ ...newCard, token });
    }

    function handleSavedCardSelect(card, index, callback) {
        setSelectedCard((prev) => ({
            ...prev,
            card,
            index,
        }));
        callback(card);
    }

    function renderButtons(cardType, submitCallback) {
        return (
            <div className="button__container force-right">
                <ButtonPrimary
                    isLoading={loading[cardType]}
                    onClick={submitCallback}
                >
                    <Text>{t("next", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        );
    }

    const savedCardEndItem = { type: "select" };
    return (
        <div className="letters-compose-add-ons__select-payment">
            <div className="letters-compose-add-ons-payment-container modal__body">
                <AddPaymentOption
                    changeCurrentSection={changeCurrentSection}
                    context="giftCard"
                    handleCardInputChange={handleCardInputChange}
                    handleCardSelect={handleCardSelect}
                    handleCardSetToken={handleCardSetToken}
                    handleCloseModal={handleCloseModal}
                    hideBackButton={true}
                    loading={loading}
                    newCard={newCard}
                    renderButtons={renderButtons}
                    setLoading={setLoading}
                    showNotification={showNotification}
                />
                <div className="new-modal-text-divider horizontal">
                    {t("or", { ns: "common" })}
                </div>
                <SavedPaymentOptions
                    changeCurrentSection={changeCurrentSection}
                    context="giftCard"
                    endItem={savedCardEndItem}
                    handleCardSelect={handleCardSelect}
                    handleCloseModal={handleCloseModal}
                    handleSavedCardSelect={handleSavedCardSelect}
                    renderButtons={renderButtons}
                    savedCards={savedCards}
                    selectedCard={selectedCard}
                />
            </div>
        </div>
    );
};
