import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, ListSubheader } from "@mui/material";
import cx from "classnames";

// CSS
import styles from "./select-group.module.scss";

/**
 * SelectGroup component that renders a Material UI Select with grouped options
 * @param {Object} props - Component props
 * @param {string} props.id - The id for the select input
 * @param {Object[]} props.groups - Array of group objects containing options
 * @param {string} props.value - Currently selected value
 * @param {function} props.onChange - Change handler function
 * @param {boolean} props.disabled - Whether the select is disabled
 * @param {string} props.className - Additional CSS class names
 * @param {string} props.variant - MUI Select variant (outlined, filled, or standard)
 * @returns {JSX.Element} SelectGroup component
 */
export const SelectGroup = ({
    className = "",
    disabled = false,
    id,
    groups,
    onChange,
    value,
    variant = "outlined",
    ...props
}) => {
    return (
        <Select
            className={cx(styles.selectGroup, className)}
            disabled={disabled}
            id={id}
            onChange={onChange}
            value={value}
            variant={variant}
            {...props}
        >
            {groups.map((group) => [
                <ListSubheader
                    className={styles.subheader}
                    key={`group-${group.label}`}
                >
                    {group.label}
                </ListSubheader>,
                group.options.map((option) => (
                    <MenuItem
                        className={styles.menuItem}
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                )),
            ])}
        </Select>
    );
};

SelectGroup.propTypes = {
    id: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                    ]).isRequired,
                    label: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
};
