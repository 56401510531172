import React, { createContext, useContext, useEffect, useState } from "react";

// Context
import { AuthContext } from "context/auth/auth";

// Logging
import { AnalyticsLogger } from "../loggers/AnalyticsLogger";

// Enums
import { PurchaseModalNavigation } from "components/Purchase/enums/enums";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { emptyFunction, isEmptyObject } from "utils/objectUtils";

// Constants
export const PurchaseContext = createContext({});

/**
 * TODO: Consider extracting card information to separate context
 */
export const PurchaseProvider = ({ children }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     **/

    const { isLoggingOut } = useContext(AuthContext);

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        PurchaseModalNavigation.BUNDLES
    );
    const [discount, setDiscount] = useState({
        code: "",
        message: t("purchase_modal.add_promo_code", { ns: "components" }),
        isPreloaded: false,
        status: "inactive",
    });
    const [purchaseMode, setPurchaseMode] = useState("letters");
    const [onCompletePurchaseFlow, setOnCompletePurchaseFlow] = useState(
        () => emptyFunction
    );
    const [purchase, setPurchase] = useState({
        addOns: null,
        bundle: null,
        giftCard: null,
        originalBundle: null,
        totalPrice: null,
    });
    const [recentPaymentMethod, setRecentPaymentMethod] = useState({
        details: {},
        method: "",
    });
    const [savedCards, setSavedCards] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        if (isLoggingOut) {
            resetPurchaseContext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggingOut]);

    useEffect(() => {
        if (recentPaymentMethod) {
            const { details, method } = recentPaymentMethod;
            if (!isEmptyObject(details) && method) {
                setSelectedCard((prevSelectedCard) => ({
                    card: details,
                    isSavedCard: true,
                }));
            }
        }
    }, [recentPaymentMethod]);

    /**
     * End Hooks
     */

    /**
     * TODO: Update method to account for other payment methods when Paypal or other methods are accepted
     */
    async function fetchRecentPaymentMethod() {
        try {
            const { json } = await SandboxxRestAPI.getRecentPaymentMethod();
            if (json.paymentMethod === "BRAINTREE") {
                setRecentPaymentMethod((prev) => ({
                    ...prev,
                    details: json.braintreeCard,
                    method: json.paymentMethod,
                }));
            }
        } catch (err) {}
    }

    async function fetchSavedCards() {
        try {
            const { json } = await SandboxxRestAPI.getBraintreeSavedCards();
            setSavedCards(json);
        } catch (err) {}
    }

    async function handleApplyDiscount() {
        try {
            setDiscount((prev) => ({ ...prev, status: "loading" }));
            const payload = {
                bundleId: purchase.bundle.id,
                code: discount.code,
            };
            const { json } = await SandboxxRestAPI.applyDiscount(payload);
            onApplyDiscountSuccess(json);
        } catch (err) {
            onApplyDiscountError(err);
        }
    }

    /**
     * This method (1) resets discount to its original state and (2) removes
     * the discount that may be applied to the currently selected bundle
     */
    function handleCancelDiscount() {
        handleResetDiscount();
        setPurchase((prevPurchase) => ({
            ...prevPurchase,
            bundle: prevPurchase.originalBundle,
            originalBundle: prevPurchase.originalBundle,
            totalPrice: prevPurchase.originalBundle.price,
        }));
    }

    function handleClearPurchase() {
        setPurchase((prevPurchase) => ({
            ...prevPurchase,
            bundle: null,
            originalBundle: null,
            totalPrice: null,
        }));
    }

    function resetPurchaseContext() {
        setDiscount({
            code: "",
            isPreloaded: false,
            message: t("purchase_modal.add_promo_code", { ns: "components" }),
            status: "inactive",
        });
        setPurchase({
            addOns: null,
            bundle: null,
            giftCard: null,
            originalBundle: null,
            totalPrice: null,
        });
        setRecentPaymentMethod({
            details: {},
            method: "",
        });
        setSavedCards(null);
        setSelectedCard(null);
    }

    function handlePreloadDiscount(code) {
        setDiscount((prevDiscount) => ({
            ...prevDiscount,
            code,
            isPreloaded: true,
        }));
    }

    function handleResetDiscount() {
        setDiscount((prev) => ({
            code: "",
            isPreloaded: false,
            message: t("purchase_modal.add_promo_code", { ns: "components" }),
            status: "inactive",
        }));
    }

    function handleSelectBundle(selectedBundle) {
        setPurchase((prev) => ({
            ...prev,
            bundle: selectedBundle,
            originalBundle: selectedBundle,
            totalPrice: selectedBundle.price,
        }));
    }

    function onApplyDiscountError(err) {
        const errorMessage = getErrorMessage(err);
        AnalyticsLogger.logPromoCodeFailure({ discount });
        setDiscount((prev) => ({
            ...prev,
            message: errorMessage,
            status: "error",
        }));
    }

    function onApplyDiscountSuccess(discountedBundle) {
        AnalyticsLogger.logPromoCodeSuccess({ discount, discountedBundle });
        setDiscount((prev) => ({
            ...prev,
            message: t("purchase_modal.promo_code_added", { ns: "components" }),
            status: "active",
        }));
        setPurchase((prev) => ({
            ...prev,
            bundle: discountedBundle,
            originalBundle: purchase.bundle,
            totalPrice: discountedBundle.price,
        }));
    }

    const purchaseContext = {
        currentSection,
        discount,
        fetchRecentPaymentMethod,
        fetchSavedCards,
        handleApplyDiscount,
        handleCancelDiscount,
        handleClearPurchase,
        handlePreloadDiscount,
        handleResetDiscount,
        handleSelectBundle,
        onCompletePurchaseFlow,
        purchase,
        purchaseMode,
        recentPaymentMethod,
        resetPurchaseContext,
        savedCards,
        selectedCard,
        setCurrentSection,
        setDiscount,
        setOnCompletePurchaseFlow,
        setPurchase,
        setPurchaseMode,
        setSelectedCard,
        setShowPurchaseModal,
        showPurchaseModal,
    };

    return (
        <PurchaseContext.Provider value={purchaseContext}>
            {children}
        </PurchaseContext.Provider>
    );
};
