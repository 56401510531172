import React, { useContext, useEffect, useState } from "react";
import { Modal } from "components/Modals/Modal";
import PropTypes from "prop-types";

// Components
import { WalletManagePurchaseCardModalRecipientDetails } from "./components/WalletManagePurchaseCardModalRecipientDetails/WalletManagePurchaseCardModalRecipientDetails";
import { WalletManagePurchaseCardModalSelectAmount } from "./components/WalletManagePurchaseCardModalSelectAmount/WalletManagePurchaseCardModalSelectAmount";
import { WalletManagePurchaseCardModalSelectDesign } from "./components/WalletManagePurchaseCardModalSelectDesign/WalletManagePurchaseCardModalSelectDesign";
import { WalletManagePurchaseCardModalSelectPayment } from "./components/WalletManagePurchaseCardModalSelectPayment/WalletManagePurchaseCardModalSelectPayment";
import { WalletManagePurchaseCardModalReview } from "./components/WalletManagePurchaseCardModalReview/WalletManagePurchaseCardModalReview";

// Context
import { PurchaseContext } from "context/purchase";

// CSS
import styles from "./wallet-manage-purchase-card-modal.module.scss";

// Hooks
import { useWalletManageGiftCard } from "./hooks/useWalletManageGiftCard";
import { useWalletManagePurchaseCardModal } from "./hooks/useWalletManagePurchaseCardModal";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { isEmptyObject } from "utils/objectUtils";

// Constants
import { NavigationEnum } from "./constants/constants";
const {
    RECIPIENT_DETAILS,
    REVIEW,
    SELECT_AMOUNT,
    SELECT_DESIGN,
    SELECT_PAYMENT,
} = NavigationEnum;

export const WalletManagePurchaseCardModal = (props) => {
    const {
        fetchPurchasedGiftCards,
        fetchSpendingGiftCards,
        setShouldShowPurchaseCardModal,
        shouldShowPurchaseCardModal,
        showNotification,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const {
        fetchRecentPaymentMethod,
        fetchSavedCards,
        recentPaymentMethod,
        savedCards,
        selectedCard,
        setSelectedCard,
    } = useContext(PurchaseContext);

    /**
     * Custom Hooks
     */

    const { currentSection, purchaseInfo, setCurrentSection, setPurchaseInfo } =
        useWalletManagePurchaseCardModal(props);

    const {
        clearGiftCard,
        fetchWalletGiftCardOptions,
        giftCard,
        selectGiftCardAmount,
        setGiftCard,
    } = useWalletManageGiftCard();

    /**
     * useState
     */

    const [loading, setLoading] = useState({
        braintreeNewCard: false,
        braintreeSavedCard: false,
        purchase: false,
    });
    const [newCard, setNewCard] = useState({
        id: null,
        name: null,
        saveCard: true,
        token: null,
    });

    /**
     * useEffect
     */

    useEffect(() => {
        fetchRecentPaymentMethod();
        fetchSavedCards();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (recentPaymentMethod) {
            const { details, method } = recentPaymentMethod;
            if (!isEmptyObject(details) && method) {
                setSelectedCard((prev) => ({
                    ...prev,
                    card: details,
                    isSavedCard: true,
                }));
            }
        }
    }, [recentPaymentMethod, setSelectedCard]);

    /**
     * End Hooks
     */

    function getHeaderText(section) {
        switch (section) {
            case "RECIPIENT_DETAILS":
                return t("recipient_details", { ns: "common" });
            case "REVIEW":
                return t("confirm_purchase", { ns: "common" });
            case "SELECT_AMOUNT":
                return t("select_an_amount", { ns: "common" });
            case "SELECT_DESIGN":
                return t("select_a_design", { ns: "common" });
            case "SELECT_PAYMENT":
                return t("enter_gift_card_payment_information", {
                    ns: "wallet",
                });
            default:
                return "";
        }
    }

    function handleClosePurchaseCardModal() {
        setShouldShowPurchaseCardModal(false);
        resetModalState();
    }

    async function handlePurchase() {
        const { email, isForCurrentUser } = purchaseInfo;

        // Update loading state
        setLoading((prev) => ({ ...prev, purchase: true }));

        // Prepare payloads for purchase request
        // - Only include `spendingUsersEmail` in payload if `selfPurchase` is false
        const giftCardPayload = {
            ...(!isForCurrentUser && {
                spendingUsersEmail: email,
            }),
            selfPurchase: isForCurrentUser,
            sponsor: giftCard.selected.sponsor,
            value: giftCard.amount.base.toString(),
        };
        const paymentPayload = {
            paymentMethod: "BRAINTREE",
            paymentToken: selectedCard.card.token,
            paymentNonce: null,
        };

        // Make purchase request
        try {
            const { json } = await SandboxxRestAPI.purchaseWalletGiftCard({
                giftcard: giftCardPayload,
                payment: paymentPayload,
            });
            onPurchaseSuccess(json);
        } catch (err) {
            onPurchaseFailure(err);
        }
    }

    function onPurchaseFailure(err) {
        const errorMessage = getErrorMessage(err);
        setLoading((prev) => ({ ...prev, purchase: false }));
        showNotification({ text: errorMessage, type: "warning" });
    }

    function onPurchaseSuccess(res) {
        showNotification({ text: res.message, type: "success" });
        fetchPurchasedGiftCards();
        fetchSpendingGiftCards();
        setShouldShowPurchaseCardModal(false);
        resetModalState();
    }

    function resetModalState() {
        clearGiftCard();
        setCurrentSection(RECIPIENT_DETAILS);
        setLoading({
            braintreeNewCard: false,
            braintreeSavedCard: false,
            purchase: false,
        });
        setPurchaseInfo({
            email: "",
            isForCurrentUser: false,
            provider: "",
        });
        setNewCard({
            id: null,
            name: null,
            saveCard: true,
            token: null,
        });
    }

    function renderCurrentSection() {
        return {
            [RECIPIENT_DETAILS]: (
                <WalletManagePurchaseCardModalRecipientDetails
                    {...props}
                    fetchWalletGiftCardOptions={fetchWalletGiftCardOptions}
                    handleClosePurchaseCardModal={handleClosePurchaseCardModal}
                    purchaseInfo={purchaseInfo}
                    setCurrentSection={setCurrentSection}
                    setPurchaseInfo={setPurchaseInfo}
                    setShouldShowPurchaseCardModal={
                        setShouldShowPurchaseCardModal
                    }
                />
            ),
            [REVIEW]: (
                <WalletManagePurchaseCardModalReview
                    {...props}
                    giftCard={giftCard}
                    handleClosePurchaseCardModal={handleClosePurchaseCardModal}
                    handlePurchase={handlePurchase}
                    loading={loading}
                    selectedCard={selectedCard}
                    setCurrentSection={setCurrentSection}
                    setLoading={setLoading}
                    setSelectedCard={setSelectedCard}
                />
            ),
            [SELECT_AMOUNT]: (
                <WalletManagePurchaseCardModalSelectAmount
                    {...props}
                    giftCard={giftCard}
                    handleClosePurchaseCardModal={handleClosePurchaseCardModal}
                    newCard={newCard}
                    recentPaymentMethod={recentPaymentMethod}
                    selectGiftCardAmount={selectGiftCardAmount}
                    setCurrentSection={setCurrentSection}
                    setNewCard={setNewCard}
                    setShouldShowPurchaseCardModal={
                        setShouldShowPurchaseCardModal
                    }
                />
            ),
            [SELECT_DESIGN]: (
                <WalletManagePurchaseCardModalSelectDesign
                    {...props}
                    giftCard={giftCard}
                    handleClosePurchaseCardModal={handleClosePurchaseCardModal}
                    setCurrentSection={setCurrentSection}
                    setGiftCard={setGiftCard}
                    setPurchaseInfo={setPurchaseInfo}
                    setShouldShowPurchaseCardModal={
                        setShouldShowPurchaseCardModal
                    }
                />
            ),
            [SELECT_PAYMENT]: (
                <WalletManagePurchaseCardModalSelectPayment
                    {...props}
                    handleClosePurchaseCardModal={handleClosePurchaseCardModal}
                    loading={loading}
                    newCard={newCard}
                    fetchSavedCards={fetchSavedCards}
                    savedCards={savedCards}
                    selectedCard={selectedCard}
                    setCurrentSection={setCurrentSection}
                    setLoading={setLoading}
                    setNewCard={setNewCard}
                    setSelectedCard={setSelectedCard}
                    showNotification={showNotification}
                />
            ),
        }[currentSection];
    }

    return (
        <Modal
            className={styles["wallet-add-card-modal"]}
            cyId="wallet-purchase-card-modal"
            headerText={getHeaderText(currentSection)}
            onHide={handleClosePurchaseCardModal}
            show={shouldShowPurchaseCardModal}
        >
            {renderCurrentSection()}
        </Modal>
    );
};

WalletManagePurchaseCardModal.propTypes = {
    fetchPurchasedGiftCards: PropTypes.func.isRequired,
    fetchSpendingGiftCards: PropTypes.func.isRequired,
    setShouldShowPurchaseCardModal: PropTypes.func.isRequired,
    shouldShowPurchaseCardModal: PropTypes.bool.isRequired,
    showNotification: PropTypes.func.isRequired,
};
