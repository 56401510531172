// Components
import { CountDownTimer } from "components/CountDownTimer/CountDownTimer";

// Utils
import { isEmptyObject } from "utils/objectUtils";
import { findTimeDifference } from "utils/dateTimeUtils";

export function prettifyTimeUntilCutoff(cutoffTime) {
    const timeDifference = findTimeDifference(cutoffTime);
    const { hours, minutes } = timeDifference;
    const hoursPretty = Math.floor(hours);
    const minutesPretty = Math.floor(minutes);

    if (
        isEmptyObject(timeDifference) ||
        hours < 0 ||
        (hours === 0 && minutes < 0)
    ) {
        return null;
    } else if (hours === 0 && minutes >= 0) {
        return <CountDownTimer endTime={cutoffTime} />;
    } else {
        return `${hoursPretty}h ${minutesPretty}m`;
    }
}
