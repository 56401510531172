import { useContext, useMemo } from "react";

// Context
import { ContactsContext } from "context/contacts";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

// Localization
import { useTranslation } from "localization/localization";
import { getCountryTranslation } from "localization/utils";

// Models
import { Contact } from "models/contacts/Contact";

export const useLettersComposeReviewRecipientSender = ({
    parentKey,
    recipient,
    sender,
    setRecipient,
}) => {
    /**
     * Custom Hooks
     */

    const { i18n } = useTranslation();

    /**
     * useContext
     */

    const {
        fetchContacts,
        fetchRecentContacts,
        toggleContactModal,
        setOnSubmitContactSuccessFinalCallback,
    } = useContext(ContactsContext);

    const senderCountryPretty = useMemo(() => {
        return sender?.address?.country
            ? getCountryTranslation(
                  sender.address.country,
                  i18n.resolvedLanguage
              )
            : "";
    }, [i18n.resolvedLanguage, sender]);

    /**
     * Constants
     */

    const hasSenderAddress =
        sender &&
        sender.address.line1 &&
        sender.address.city &&
        sender.address.state &&
        sender.address.zipcode;
    const isRecipient = parentKey === "recipient";
    const isSender = parentKey === "sender";

    /**
     * End Hooks
     */

    function handleToggleContactModal() {
        toggleContactModal({
            contactToEdit: recipient,
            isWritingLetter: true,
            shouldShow: true,
            targetScreen: PersonModalNavigation.FORM,
            type: "edit",
        });
        setOnSubmitContactSuccessFinalCallback(() => onEditContactSuccess);
    }

    function onEditContactSuccess(res, { contactStaging }) {
        // Fetch latest contacts and recent contacts lists
        fetchContacts();
        fetchRecentContacts();

        // Save new contact locally
        const contact = new Contact({
            ...contactStaging,
            abContactId: res.abContactId,
        });
        localStorage.setItem(
            "sandboxxMessageRecipient",
            JSON.stringify(contact.generateContactObject())
        );
        setRecipient(contact);
    }

    return {
        handleToggleContactModal,
        hasSenderAddress,
        isRecipient,
        isSender,
        senderCountryPretty,
    };
};
