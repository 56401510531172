if (!window.OffscreenCanvas) {
    window.OffscreenCanvas = class OffscreenCanvas {
        constructor(width, height) {
            this.canvas = document.createElement("canvas");
            this.canvas.width = Math.max(1, width);
            this.canvas.height = Math.max(1, height);
            this.canvas.convertToBlob = () => {
                return new Promise((resolve, reject) => {
                    try {
                        this.canvas.toBlob(resolve, "image/jpeg");
                    } catch (err) {
                        reject(err);
                    }
                });
            };
            return this.canvas;
        }
    };
}
