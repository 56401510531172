import React from "react";

// Components
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Text } from "components/Text/Text";

// CSS
import styles from "./community-modal-features.module.scss";

// Hooks
import { useTranslation } from "localization/localization";

export const CommunityModalFeatures = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    const features = [
        {
            icon: <ChatBubbleOutlineIcon />,
            style: {
                backgroundColor: "var(--pacific-blue-20)",
                color: "var(--pacific-blue-100)",
            },
            subtitle: t("community_modal.features.connect_share.subtitle", {
                ns: "components",
            }),
            title: t("community_modal.features.connect_share.title", {
                ns: "components",
            }),
        },
        {
            icon: <LibraryBooksIcon />,
            style: {
                backgroundColor: "var(--burnt-orange-20)",
                color: "var(--burnt-orange-100)",
            },
            subtitle: t(
                "community_modal.features.exclusive_resources.subtitle",
                { ns: "components" }
            ),
            title: t("community_modal.features.exclusive_resources.title", {
                ns: "components",
            }),
        },
        {
            icon: <CardGiftcardIcon />,
            style: {
                backgroundColor: "var(--mission-orange-20)",
                color: "var(--mission-orange-100)",
            },
            subtitle: t("community_modal.features.perks_giveaways.subtitle", {
                ns: "components",
            }),
            title: t("community_modal.features.perks_giveaways.title", {
                ns: "components",
            }),
        },
        {
            icon: <NotificationsIcon />,
            style: {
                backgroundColor: "var(--od-green-20)",
                color: "var(--od-green-100)",
            },
            subtitle: t("community_modal.features.real_time_updates.subtitle", {
                ns: "components",
            }),
            title: t("community_modal.features.real_time_updates.title", {
                ns: "components",
            }),
        },
    ];

    return (
        <div className={styles.features}>
            {features.map((feature, index) => (
                <div key={index} className={styles.feature}>
                    <div className={styles.icon} style={feature.style}>
                        {feature.icon}
                    </div>
                    <Text classes={styles.title}>{feature.title}</Text>
                    <Text classes={styles.subtitle}>{feature.subtitle}</Text>
                </div>
            ))}
        </div>
    );
};
