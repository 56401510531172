import React, { useContext, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import Fuse from "fuse.js";
import PropTypes from "prop-types";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Components
import { Modal } from "components/Modals/Modal";
import { SupportSquadSettingsModalCreateName } from "./components/SupportSquadSettingsModalCreate/components/SupportSquadSettingsModalCreateName/SupportSquadSettingsModalCreateName";
import { SupportSquadSettingsModalCreateAssign } from "./components/SupportSquadSettingsModalCreate/components/SupportSquadSettingsModalCreateAssign/SupportSquadSettingsModalCreateAssign";
import { SupportSquadSettingsModalCreateInvite } from "./components/SupportSquadSettingsModalCreate/components/SupportSquadSettingsModalCreateInvite/SupportSquadSettingsModalCreateInvite";
import { SupportSquadSettingsModalCreateInviteSuccess } from "./components/SupportSquadSettingsModalCreate/components/SupportSquadSettingsModalCreateInviteSuccess/SupportSquadSettingsModalCreateInviteSuccess";
import { SupportSquadSettingsModalManage } from "./components/SupportSquadSettingsModalManage/SupportSquadSettingsModalManage";
import { SupportSquadSettingsModalMembers } from "./components/SupportSquadSettingsModalMembers/SupportSquadSettingsModalMembers";

// Context
import { ContactsContext } from "context/contacts";
import { NotificationContext } from "context/notification/notification";

// CSS
import styles from "./support-squad-settings-modal.module.scss";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";
import { NotificationSeverity } from "enums/notifications/NotificationSeverity";

// Hooks
import { useDebounceValue } from "../../../../hooks/debounce/useDebounceValue";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Notification } from "models/notification/Notification";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { selectImageFile } from "utils/imageUtils";
import { emptyFunction } from "utils/objectUtils";

// Validation
import { Validation } from "validation/validation";

export const SupportSquadSettingsModal = (props) => {
    const {
        currentSection,
        handleImageUpload,
        isCreatingSquad,
        isUpdatingSquad,
        isUploadingImage,
        setCurrentSection,
        setSelectedUserListItem,
        setShouldShowDeleteModal,
        setShouldShowImageCropModal,
        setShouldShowCreateModal,
        setSquadName,
        setSquadRecipientId,
        setSupportSquadImage,
        shouldShowCreateModal,
        squadAdmins,
        squadMembers,
        squadName,
        selectedSquad,
        supportSquadImage,
        validateAndSubmit,
    } = props;

    /**
     * useContext
     **/

    const {
        contacts,
        setOnSubmitContactSuccessFinalCallback,
        toggleContactModal,
    } = useContext(ContactsContext);
    const { addNotification } = useContext(NotificationContext);

    /**
     * useState
     **/

    const [fuse, setFuse] = useState(
        new Fuse(contacts, { keys: ["firstName", "fullName", "lastName"] })
    );
    const [headerText, setHeaderText] = useState("");
    const [headerSubText, setHeaderSubText] = useState("");
    const [inviteEmails, setInviteEmails] = useState(["", "", ""]);
    const [isSendingEmails, setIsSendingEmails] = useState(false);
    const [numberOfEmails, setNumberOfEmails] = useState(3);
    const [results, setResults] = useState([]);
    const [term, setTerm] = useState("");

    /**
     * useDebounce
     **/

    const debouncedSearchTerm = useDebounceValue(term, 250);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (contacts && contacts.length) {
            setFuse(
                new Fuse(contacts, {
                    keys: ["firstName", "fullName", "lastName"],
                    threshold: 0.3,
                })
            );
            setResults(contacts);
        }
    }, [contacts]);

    useEffect(() => {
        if (!debouncedSearchTerm) {
            setResults(contacts);
        } else {
            setResults(
                fuse.search(debouncedSearchTerm).map(({ item }) => item)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    /**
     * useMemo
     **/

    const hasEnteredAnEmail = useMemo(
        () =>
            inviteEmails.some(
                (email) => email && Validation.isEmailAddress(email)
            ),
        [inviteEmails]
    );

    /**
     * End Hooks
     **/

    function handleAddEmail() {
        setNumberOfEmails(numberOfEmails + 1);
        setInviteEmails((prev) => [...prev, ""]);
    }

    function handleClearData() {
        setSquadRecipientId("");
        setCurrentSection("name");
        setInviteEmails(["", "", ""]);
        setNumberOfEmails(3);
        setResults(contacts);
        setSquadName("");
        setSelectedUserListItem({});
        setSupportSquadImage({
            imageFile: "",
            imageName: "",
            imagePreviewUrl: "",
        });
        setTerm("");
    }

    function handleContactClick({ suggestion: contact }) {
        setSquadRecipientId(contact.id);
        setSelectedUserListItem(contact);
    }

    function handleCreateAndUpdateSupportSquad() {
        if (supportSquadImage.imagePreviewUrl) {
            handleImageUpload(supportSquadImage);
        } else {
            validateAndSubmit();
        }
    }

    function handleEmailChange(index, e) {
        const placeHolderArray = inviteEmails;
        placeHolderArray[index] = e.target.value;
        setInviteEmails([...placeHolderArray]);
    }

    function handleImageFileSelect(e) {
        selectImageFile(e, {
            onLoadEnd: (file, reader) => {
                setSupportSquadImage({
                    imageName: file.name,
                    imagePreviewUrl: reader.result,
                    imageFile: file,
                });
                setShouldShowCreateModal(false);
                setShouldShowImageCropModal(true);
            },
        });
    }

    function handleModalClose() {
        setShouldShowCreateModal(false);
        handleClearData();
    }

    function handleNameChange({ target }) {
        const { value } = target;
        setSquadName(value);
    }

    function handleSearchInputChange(e) {
        const { value } = e.target;
        setTerm(value);
    }

    async function handleSubmitEmailInvites(e) {
        e.preventDefault();
        setIsSendingEmails(true);
        const validEmails = inviteEmails.filter(
            (email) => email && Validation.isEmailAddress(email)
        );
        try {
            await SandboxxRestAPI.submitSupportSquadEmailInvites({
                squadId: selectedSquad.squadId.toString(),
                supportSquadEmails: validEmails,
            });
            onSubmitEmailInviteSuccess(validEmails);
        } catch (err) {
            onSubmitEmailInviteFailure(err);
        }
    }

    function onDeleteSquadClick() {
        if (squadMembers.length > 0) {
            setCurrentSection("removeMemberWarning");
            setShouldShowCreateModal(false);
            setShouldShowDeleteModal(true);
        } else {
            setCurrentSection("deleteSquad");
            setShouldShowCreateModal(false);
            setShouldShowDeleteModal(true);
        }
    }

    function onSubmitEmailInviteFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIsSendingEmails(false);
        addNotification(
            new Notification({
                id: "SUBMIT_EMAIL_INVITE_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onSubmitEmailInviteSuccess(validEmails) {
        validEmails.forEach(() =>
            AnalyticsLogger.logSquadInviteSent({
                referrals: validEmails,
                squad: selectedSquad,
            })
        );
        setCurrentSection("inviteSuccess");
        setIsSendingEmails(false);
    }

    function openContactModalCreate() {
        toggleContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            isSupportSquad: true,
            isWritingLetter: false,
            shouldShow: true,
            shouldShowBackButton: true,
            targetScreen: PersonModalNavigation.NAME,
            type: "create",
        });
        setOnSubmitContactSuccessFinalCallback(() => emptyFunction);
        setShouldShowCreateModal(false);
    }

    function openContactModalEdit() {
        toggleContactModal({
            contactToEdit: {
                squadId: selectedSquad.squadId,
                squadName: selectedSquad.squadName,
                headerLargeUrl: selectedSquad.headerLargeUrl,
                headerSmallUrl: selectedSquad.headerSmallUrl,
                address: selectedSquad.supporteeAddress,
                firstName: selectedSquad.supporteeFirstName,
                fullName: selectedSquad.supporteeFullName,
                lastName: selectedSquad.supporteeLastName,
                title: selectedSquad.supporteeTitle,
            },
            isSupportSquad: true,
            isWritingLetter: false,
            shouldShow: true,
            shouldShowBackButton: true,
            targetScreen: PersonModalNavigation.FORM,
            type: "edit",
        });
        setOnSubmitContactSuccessFinalCallback(() => emptyFunction);
        setShouldShowCreateModal(false);
    }

    function renderModalContent() {
        switch (currentSection) {
            case "name":
            case "edit-details":
                return (
                    <SupportSquadSettingsModalCreateName
                        {...props}
                        handleImageFileSelect={handleImageFileSelect}
                        handleModalClose={handleModalClose}
                        handleNameChange={handleNameChange}
                        handleCreateAndUpdateSupportSquad={
                            handleCreateAndUpdateSupportSquad
                        }
                        isCreatingSquad={isCreatingSquad}
                        isUpdatingSquad={isUpdatingSquad}
                        isUploadingImage={isUploadingImage}
                        setHeaderSubText={setHeaderSubText}
                        setHeaderText={setHeaderText}
                        selectedSquad={selectedSquad}
                    />
                );
            case "assign":
                return (
                    <SupportSquadSettingsModalCreateAssign
                        {...props}
                        handleContactClick={handleContactClick}
                        handleCreateAndUpdateSupportSquad={
                            handleCreateAndUpdateSupportSquad
                        }
                        handleSearchInputChange={handleSearchInputChange}
                        isCreatingSquad={isCreatingSquad}
                        openContactModalCreate={openContactModalCreate}
                        results={results}
                        setCurrentSection={setCurrentSection}
                        setHeaderSubText={setHeaderSubText}
                        setHeaderText={setHeaderText}
                        term={term}
                    />
                );
            case "invite":
                return (
                    <SupportSquadSettingsModalCreateInvite
                        {...props}
                        handleAddEmail={handleAddEmail}
                        handleEmailChange={handleEmailChange}
                        handleModalClose={handleModalClose}
                        handleSubmitEmailInvites={handleSubmitEmailInvites}
                        inviteEmails={inviteEmails}
                        hasEnteredAnEmail={hasEnteredAnEmail}
                        isSendingEmails={isSendingEmails}
                        numberOfEmails={numberOfEmails}
                        setHeaderSubText={setHeaderSubText}
                        setHeaderText={setHeaderText}
                        squadName={squadName}
                        selectedSquad={selectedSquad}
                    />
                );
            case "inviteSuccess":
                return (
                    <SupportSquadSettingsModalCreateInviteSuccess
                        handleModalClose={handleModalClose}
                        setCurrentSection={setCurrentSection}
                        setHeaderSubText={setHeaderSubText}
                        setHeaderText={setHeaderText}
                        setInviteEmails={setInviteEmails}
                    />
                );
            case "settings":
                return (
                    <SupportSquadSettingsModalManage
                        {...props}
                        onDeleteSquadClick={onDeleteSquadClick}
                        openContactModalEdit={openContactModalEdit}
                        setHeaderSubText={setHeaderSubText}
                        setHeaderText={setHeaderText}
                    />
                );
            case "members":
                return (
                    <SupportSquadSettingsModalMembers
                        setHeaderText={setHeaderText}
                        setHeaderSubText={setHeaderSubText}
                        squadAdmins={squadAdmins}
                        squadMembers={squadMembers}
                    />
                );
            default:
                break;
        }
    }

    return (
        <Modal
            className={styles["support-squad-settings-modal"]}
            cypressCloseButtonTestId="support-squad-settings-modal-close-button"
            dialogClassName={cx(styles["support-squad-settings-modal-dialog"], {
                [styles["support-squad-settings-modal-dialog--photo-edit"]]:
                    currentSection === "imageCrop",
            })}
            onHide={handleModalClose}
            shouldHideHeader={!headerText}
            headerSubText={headerSubText}
            headerText={headerText}
            show={shouldShowCreateModal}
            size={currentSection === "inviteSuccess" ? "sm" : ""}
        >
            {renderModalContent()}
        </Modal>
    );
};

SupportSquadSettingsModal.propTypes = {
    currentSection: PropTypes.string.isRequired,
    handleImageUpload: PropTypes.func.isRequired,
    isCreatingSquad: PropTypes.bool.isRequired,
    isUpdatingSquad: PropTypes.bool.isRequired,
    isUploadingImage: PropTypes.bool.isRequired,
    loadingContacts: PropTypes.object.isRequired,
    removeSquadMembers: PropTypes.func.isRequired,
    selectedUserListItem: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setSelectedUserListItem: PropTypes.func.isRequired,
    setShouldShowDeleteModal: PropTypes.func.isRequired,
    setShouldShowImageCropModal: PropTypes.func.isRequired,
    setShouldShowCreateModal: PropTypes.func.isRequired,
    setSquadName: PropTypes.func.isRequired,
    setSquadRecipientId: PropTypes.func.isRequired,
    setSupportSquadImage: PropTypes.func.isRequired,
    shouldShowCreateModal: PropTypes.bool.isRequired,
    squadMembers: PropTypes.array,
    squadName: PropTypes.string,
    squadRecipientId: PropTypes.string,
    selectedSquad: PropTypes.object,
    supportSquadImage: PropTypes.object,
    validateAndSubmit: PropTypes.func.isRequired,
};
