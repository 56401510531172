// Data
import countriesData from "data/countries.json";

/**
 * Detects the user's language based on the browser's language settings.
 * If this function throws an error, default to english ("en").
 *
 * @returns {String} the user's language
 */
export function detectUserLanguage() {
    try {
        return navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;
    } catch (error) {
        return "en";
    }
}

export const getCountryTranslation = (countryCode, language) => {
    if (!countryCode || !language) return "";

    const country = countriesData.find(
        (country) => countryCode === country.cca2
    );

    if (!country?.translations?.[language]?.common) {
        return country?.translations?.eng?.common || countryCode;
    }

    return country.translations[language].common;
};
