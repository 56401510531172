import { useContext, useEffect, useState } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "utils/sandboxx";
import { copyTextToClipboard } from "utils/textUtils";

export const useReferralsShareLink = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [referralLink, setReferralLink] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        generateReferralLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    async function generateReferralLink() {
        try {
            const { json } = await SandboxxRestAPI.getReferralCode();
            onGenerateReferralLinkSuccess(json);
        } catch (err) {
            onGenerateReferralLinkFailure(err);
        }
    }

    function handleCopyReferralLinkToClipboard() {
        copyTextToClipboard(referralLink);
        showNotification({
            text: t("share_link.copy_success", { ns: "referrals" }),
            type: "success",
        });
    }

    function onGenerateReferralLinkFailure(err) {
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onGenerateReferralLinkSuccess({ referralMagicLink }) {
        setReferralLink(referralMagicLink);
    }

    return {
        handleCopyReferralLinkToClipboard,
        referralLink,
    };
};
