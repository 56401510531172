import React from "react";

// Components
import { CarouselV2 } from "components/CarouselV2/CarouselV2";
import { DashboardNewsPreviewCard } from "./components/DashboardNewsPreviewCard/DashboardNewsPreviewCard";

// CSS
import styles from "./dashboard-news.module.scss";

// Hooks
import { useDashboardNews } from "./hooks/useDashboardNews";

// Localization
import { useTranslation } from "localization/localization";

// Constants
const sandboxxNewsUrl =
    "https://sandboxx.us/news/?utm_source=webapp&utm_medium=navigation&utm_campaign=news";

export const DashboardNews = () => {
    /**
     * Custom Hooks
     **/

    const { isLoading, newsItems } = useDashboardNews();
    const { t } = useTranslation();

    /**
     * End Hooks
     **/

    function renderLoading() {
        return new Array(3).fill(
            <DashboardNewsPreviewCard isLoading key={crypto.randomUUID()} />
        );
    }

    function renderNewsItems() {
        if (newsItems) {
            const newsItemElements = newsItems?.map((newsItem) => (
                <DashboardNewsPreviewCard
                    isLoading={isLoading}
                    key={newsItem.id}
                    newsItem={newsItem}
                />
            ));
            for (let i = 0; i < 2; i++) {
                newsItemElements.push(
                    <div
                        key={`placeholder-${i}`}
                        className={styles.placeholder}
                    />
                );
            }
            return newsItemElements;
        }
    }

    return (
        <div className={styles.dashboardNews}>
            <CarouselV2
                classNames={{
                    container: styles.carousel,
                    item: styles.item,
                    wrapper: "",
                }}
                gap={24}
                links={[
                    {
                        className: "",
                        text: t("show_all", { ns: "common" }),
                        url: sandboxxNewsUrl,
                    },
                ]}
                title={t("news", { ns: "common" })}
                maxItems={newsItems?.length || 0}
            >
                {isLoading ? renderLoading() : renderNewsItems()}
            </CarouselV2>
        </div>
    );
};
