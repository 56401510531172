import { t } from "localization/localization";

export function generateWelcomeText(suggestedBase) {
    if (!suggestedBase?.name) {
        return "";
    }

    if (suggestedBase.branch) {
        return t("community_modal.welcome_text.with_branch", {
            base: suggestedBase.name,
            branch: suggestedBase.branch,
            ns: "components",
        });
    }

    return t("community_modal.welcome_text.default", {
        base: suggestedBase.name,
        ns: "components",
    });
}
