import React from "react";

// Components
import { NotificationBar } from "../NotificationBar/NotificationBar";

// Context
import { AuthProvider } from "context/auth/auth";
import { BasesProvider } from "context/bases";
import { BundlesProvider } from "context/bundles";
import { CartProvider } from "context/cart/cart";
import { CommunityProvider } from "context/community";
import { ContactsProvider } from "context/contacts";
import { CreditsProvider } from "context/credits";
import { DepsProvider } from "context/deps";
import { HeaderBarProvider } from "context/headerBar";
import { InterstitialProvider } from "context/interstitial";
import { IterableProvider } from "context/iterable/iterable";
import { MusterProvider } from "context/muster";
import { NavBarProvider } from "context/navbar";
import { NotificationProvider } from "context/notification/notification";
import { NotificationBarProvider } from "context/notificationBar";
import { PhoneVerificationProvider } from "context/phoneVerification";
import { PurchaseProvider } from "context/purchase";
import { RanksProvider } from "context/ranks";
import { RowndProvider } from "@rownd/react";
import { TitlesProvider } from "context/titles";

// Utils
import { DEV } from "utils/urlUtils";

// Constants
const rowndAppKey = DEV
    ? process.env.REACT_APP_ROWND_APP_KEY_STAGE
    : process.env.REACT_APP_ROWND_APP_KEY_PROD;

export const Providers = ({ children }) => {
    return (
        <RowndProvider appKey={rowndAppKey}>
            <AuthProvider>
                <CommunityProvider>
                    <NavBarProvider>
                        <NotificationBarProvider>
                            <NotificationBar />
                            <NotificationProvider>
                                <HeaderBarProvider>
                                    <CreditsProvider>
                                        <BundlesProvider>
                                            <ContactsProvider>
                                                <PhoneVerificationProvider>
                                                    <PurchaseProvider>
                                                        <CartProvider>
                                                            <RanksProvider>
                                                                <TitlesProvider>
                                                                    <BasesProvider>
                                                                        <DepsProvider>
                                                                            <MusterProvider>
                                                                                <InterstitialProvider>
                                                                                    <IterableProvider>
                                                                                        {
                                                                                            children
                                                                                        }
                                                                                    </IterableProvider>
                                                                                </InterstitialProvider>
                                                                            </MusterProvider>
                                                                        </DepsProvider>
                                                                    </BasesProvider>
                                                                </TitlesProvider>
                                                            </RanksProvider>
                                                        </CartProvider>
                                                    </PurchaseProvider>
                                                </PhoneVerificationProvider>
                                            </ContactsProvider>
                                        </BundlesProvider>
                                    </CreditsProvider>
                                </HeaderBarProvider>
                            </NotificationProvider>
                        </NotificationBarProvider>
                    </NavBarProvider>
                </CommunityProvider>
            </AuthProvider>
        </RowndProvider>
    );
};
