import React from "react";

// Components
import { CarouselV2 } from "components/CarouselV2/CarouselV2";
import { DashboardMessagesPreviewCard } from "./components/DashboardMessagesPreviewCard/DashboardMessagesPreviewCard";

// CSS
import styles from "./dashboard-messages.module.scss";

// Hooks
import { useDashboardMessages } from "./hooks/useDashboardMessages";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardMessages = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();
    const { isLoadingEmbeddedMessages, messages } = useDashboardMessages();

    /**
     * End Hooks
     */

    function renderLoading() {
        return new Array(3).fill(
            <DashboardMessagesPreviewCard isLoading key={crypto.randomUUID()} />
        );
    }

    function renderMessages() {
        if (messages) {
            const messageItems = messages?.map((message) => (
                <DashboardMessagesPreviewCard
                    isLoading={isLoadingEmbeddedMessages}
                    key={message?.metadata?.messageId}
                    message={message}
                />
            ));
            for (let i = 0; i < 2; i++) {
                messageItems.push(
                    <div
                        key={`placeholder-${i}`}
                        className={styles.placeholder}
                    />
                );
            }
            return messageItems;
        }
    }

    if (isLoadingEmbeddedMessages || (messages && messages.length > 0)) {
        return (
            <div className={styles.dashboardMessages}>
                <CarouselV2
                    classNames={{
                        container: styles.carousel,
                        item: styles.item,
                        wrapper: "",
                    }}
                    gap={24}
                    maxItems={messages?.length || 0}
                    title={t("for_you", { ns: "common" })}
                >
                    {isLoadingEmbeddedMessages
                        ? renderLoading()
                        : renderMessages()}
                </CarouselV2>
            </div>
        );
    } else {
        return null;
    }
};
