import { useContext, useRef, useState } from "react";
import * as Sentry from "@sentry/react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import {
    generateBlobFromOffscreenCanvas,
    generateInitialCrop,
    resizeFinalImage,
} from "../utils/utils";

export const useImageCropModal = ({
    aspect,
    finalWidth,
    handleSaveCroppedImage,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);

    /**
     * useRef
     */

    const imageRef = useRef(null);

    /**
     * End Hooks
     */

    function handleSetInitialCrop(e, imageRef) {
        if (imageRef.current) {
            const initialCrop = generateInitialCrop(imageRef.current, aspect);
            setCrop(initialCrop);
            setCompletedCrop(initialCrop);
        }
    }

    /**
     * Processes and submits the cropped image.
     *
     * This function takes the user's crop selection and processes it through several steps:
     * 1. Validates the crop selection is valid (has width and height)
     * 2. Calculates proper scaling between display size and actual image dimensions
     * 3. Creates an OffscreenCanvas with the dimensions of the cropped area
     * 4. Draws only the selected portion of the image onto the canvas
     * 5. Converts the canvas content to a blob and URL for preview
     * 6. Resizes the image to meet server requirements
     * 7. Passes the processed image to the parent component
     *
     * @param {Event} e - The submit event (if triggered by a form)
     * @throws {Error} If crop selection is invalid or dimensions are too small
     */
    async function handleSubmit(e) {
        try {
            // Step 1: Validate crop selection
            // Ensure the user has made a complete selection with positive dimensions
            if (
                !completedCrop ||
                !completedCrop.width ||
                !completedCrop.height
            ) {
                throw new Error("Invalid crop selection");
            }

            // Step 2: Calculate scaling factors
            // These factors convert between the display size and the actual image dimensions
            const image = imageRef.current;
            const scaleX = image.naturalWidth / image.width; // Ratio of actual width to displayed width
            const scaleY = image.naturalHeight / image.height; // Ratio of actual height to displayed height

            // Calculate the actual pixel dimensions of the crop area in the original image
            const canvasWidth = Math.floor(completedCrop.width * scaleX);
            const canvasHeight = Math.floor(completedCrop.height * scaleY);

            // Additional validation to ensure the resulting image isn't too small
            if (canvasWidth <= 0 || canvasHeight <= 0) {
                throw new Error("Invalid canvas dimensions");
            }

            // Step 3: Create an OffscreenCanvas for processing
            // This is more efficient than using a DOM canvas element
            const offscreen = new OffscreenCanvas(canvasWidth, canvasHeight);
            const ctx = offscreen.getContext("2d");

            // Step 4: Draw the cropped portion to the canvas
            // Parameters: source, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight
            ctx.drawImage(
                image, // Source image
                completedCrop.x * scaleX, // Source X position (scaled to original image)
                completedCrop.y * scaleY, // Source Y position (scaled to original image)
                completedCrop.width * scaleX, // Source width (scaled to original image)
                completedCrop.height * scaleY, // Source height (scaled to original image)
                0, // Destination X (start at left edge of canvas)
                0, // Destination Y (start at top edge of canvas)
                offscreen.width, // Destination width (fill canvas width)
                offscreen.height // Destination height (fill canvas height)
            );

            // Step 5: Generate blob and URL from the canvas
            // The blob is the binary image data, the URL is for preview/display
            const { blob, url } = await generateBlobFromOffscreenCanvas(
                offscreen
            );

            // Step 6: Resize the image to the required dimensions for the server
            // This helps optimize upload size and server storage
            const imageFile = await resizeFinalImage(blob, url, {
                width: finalWidth,
            });

            // Step 7: Submit the processed image to the parent component
            handleSaveCroppedImage({
                imageFile, // The processed and resized image file (blob)
                imagePreviewUrl: url, // URL for previewing the image
            });
        } catch (err) {
            Sentry.captureException(err);
            showNotification({
                text: t("crop_image_error", { ns: "notifications" }),
                type: "warning",
            });
        }
    }

    return {
        completedCrop,
        crop,
        handleSetInitialCrop,
        handleSubmit,
        imageRef,
        setCompletedCrop,
        setCrop,
    };
};
