export const NavBarItemKeys = Object.freeze({
    COMMUNITY: "COMMUNITY",
    INBOX: "INBOX",
    LETTERS: "LETTERS",
    MUSTER: "MUSTER",
    NEWS: "NEWS",
    PROFILE: "PROFILE",
    REFERRALS: "REFERRALS",
    SQUADS: "SQUADS",
    WALLET: "WALLET",
});
