import React from "react";

// Components
import { LettersAddOnsCarouselItem } from "../../../components/LettersAddOnsCarousel/components/LettersAddOnsCarouselItem/LettersAddOnsCarouselItem";

export const LettersComposeAddOnsSelectItems = ({
    addOns,
    changeCurrentSection,
    giftCard,
    handleAddOnsModalToggle,
    handleRemoveAddOn,
    handleSelectAddOn,
    handleGiftCardClear,
}) => {
    /**
     * Constants
     */

    const hasAddOns = addOns?.length;

    /**
     * End Constants
     */

    /**
     * * Only renders add ons that have not yet been selected by the user
     */
    function renderItems() {
        return (
            hasAddOns &&
            addOns.map((addOn) => (
                <LettersAddOnsCarouselItem
                    addOn={addOn}
                    addOns={addOns}
                    changeCurrentSection={changeCurrentSection}
                    giftCard={giftCard}
                    handleAddOnsModalToggle={handleAddOnsModalToggle}
                    handleRemoveAddOn={handleRemoveAddOn}
                    handleSelectAddOn={handleSelectAddOn}
                    handleGiftCardClear={handleGiftCardClear}
                    isInModal={true}
                    key={addOn.id}
                />
            ))
        );
    }

    return (
        <div className="letters-compose-add-ons__select-items">
            <div className="letters-compose-add-ons__select-items__list">
                {renderItems()}
            </div>
        </div>
    );
};
