import React from "react";
import PropTypes from "prop-types";

// Components
import { InfoIcon } from "components/Icons/InfoIcon";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Text } from "components/Text/Text";

// CSS
import styles from "./community-modal-actions-supported-bases.module.scss";

// Hooks
import { useCommunityModalActionsSupportedBases } from "./useCommunityModalActionsSupportedBases";
import { useTranslation } from "localization/localization";

export const CommunityModalActionsSupportedBases = ({ groupedBases }) => {
    /**
     * Custom Hooks
     */

    const { handlePopoverToggle } = useCommunityModalActionsSupportedBases();
    const { t } = useTranslation();

    /**
     * End Hooks
     */

    const basesPopover = (
        <Popover className={styles.basesPopover} id="community-bases-popover">
            <Popover.Header>
                <Text isBold>
                    {t("community_modal.supported_bases.title", {
                        ns: "components",
                    })}
                </Text>
            </Popover.Header>
            <Popover.Body>
                <Text classes={styles.subtitle}>
                    {t("community_modal.supported_bases.subtitle", {
                        ns: "components",
                    })}
                </Text>
                <div className={styles.content}>{renderBasesList()}</div>
            </Popover.Body>
        </Popover>
    );

    function renderBasesList() {
        if (!groupedBases || !groupedBases.length) return null;
        return groupedBases.map((branch) => (
            <div key={branch.label} className={styles.branchItem}>
                <Text classes={styles.branchName} isBold>
                    {branch.label}
                </Text>
                <ul className={styles.list}>
                    {branch.options.map((base) => (
                        <li key={base.value}>
                            <Text>{base.label}</Text>
                        </li>
                    ))}
                </ul>
            </div>
        ));
    }

    return (
        <div className={styles.communityModalActionsSupportedBases}>
            <OverlayTrigger
                overlay={basesPopover}
                placement="right"
                rootClose
                onToggle={handlePopoverToggle}
            >
                <button type="button" className={styles.button}>
                    <Text classes={styles.text}>
                        {t("community_modal.supported_bases.view_button", {
                            ns: "components",
                        })}
                    </Text>
                    <InfoIcon
                        className={styles.infoIcon}
                        width="14px"
                        height="14px"
                    />
                </button>
            </OverlayTrigger>
        </div>
    );
};

CommunityModalActionsSupportedBases.propTypes = {
    groupedBases: PropTypes.array.isRequired,
};
