import { useContext, useState } from "react";

// Context
import { ContactsContext } from "context/contacts";

// Enums
import { NavigationEnum } from "../../../enums/NavigationEnum";
import { RecipientSourceEnum } from "../../../enums/RecipientSourceEnum";

// Models
import { Contact } from "models/contacts/Contact";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import {
    formatNewRecipientForSubmit,
    generateIsSubmitButtonDisabled,
} from "../utils/utils";

// Validation
import { Validation } from "../validation/validation";

export const useWalletGiftCardsPurchaseRecipient = (props) => {
    const {
        contacts,
        handleSelectRecipient,
        recipient,
        recipientSource,
        setCurrentSection,
        setOrderProgress,
        showNotification,
        suggestedContacts,
    } = props;

    /**
     * useContext
     */

    const { createContact, fetchContact } = useContext(ContactsContext);

    /**
     * useState
     */

    const [isSubmittingNewRecipient, setIsSubmittingNewRecipient] =
        useState(false);
    const [newRecipient, setNewRecipient] = useState({
        city: "",
        firstName: "",
        lastName: "",
        line1: "",
        line2: "",
        state: "",
        zipcode: "",
    });
    const [shouldShowContactsModal, setShouldShowContactsModal] =
        useState(false);

    /**
     * Constants
     */

    const hasContacts = contacts && contacts.length;
    const hasSuggestedContacts = suggestedContacts && suggestedContacts.length;
    const hasContactsOrSuggestedContacts = !!(
        hasContacts || hasSuggestedContacts
    );
    const isSubmitButtonDisabled = generateIsSubmitButtonDisabled({
        isSubmittingNewRecipient,
        newRecipient,
        recipient,
        recipientSource,
    });

    /**
     * End Hooks
     */

    function handleGoBack() {
        setOrderProgress((prev) => ({ ...prev, giftCardOption: false }));
        setCurrentSection(NavigationEnum.SELECT_OPTION);
    }

    function handleSubmit(newRecipient) {
        if (
            recipientSource === RecipientSourceEnum.CONTACTS ||
            recipientSource === RecipientSourceEnum.PREVIOUS_RECIPIENTS
        ) {
            setOrderProgress((prev) => ({ ...prev, recipient: true }));
            setCurrentSection(NavigationEnum.SENDER);
        } else if (recipientSource === RecipientSourceEnum.NEW_RECIPIENT) {
            if (Validation.isValidNewRecipient(newRecipient)) {
                setIsSubmittingNewRecipient(true);
                const newRecipientFormatted =
                    formatNewRecipientForSubmit(newRecipient);
                createContact(
                    newRecipientFormatted,
                    onCreateContactSuccess,
                    onCreateContactFailure,
                    onCreateContactFailure
                );
            }
        }
    }

    function onCreateContactFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIsSubmittingNewRecipient(false);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onCreateContactSuccess({ abContactId }) {
        return fetchContact(
            abContactId,
            onFetchContactSuccess,
            onCreateContactFailure
        );
    }

    function onFetchContactSuccess(res) {
        const contact = new Contact(res);
        handleSelectRecipient(contact, RecipientSourceEnum.CONTACTS);
        setOrderProgress((prev) => ({
            ...prev,
            recipient: true,
        }));
        setIsSubmittingNewRecipient(false);
        setCurrentSection(NavigationEnum.SENDER);
    }

    return {
        handleGoBack,
        handleSubmit,
        hasContacts,
        hasContactsOrSuggestedContacts,
        hasSuggestedContacts,
        isSubmitButtonDisabled,
        isSubmittingNewRecipient,
        newRecipient,
        setNewRecipient,
        setShouldShowContactsModal,
        shouldShowContactsModal,
    };
};
