import { useContext, useMemo } from "react";

// Context
import { IterableContext } from "context/iterable/iterable";

// Enums
import { IterableEmbeddedMessagesPlacementId } from "enums/iterable/IterableEmbeddedMessagePlacementId";

// Utils
import { DEV } from "utils/urlUtils";

const embeddedMessagesPlacementId = DEV
    ? IterableEmbeddedMessagesPlacementId.DASHBOARD_ADS_STAGE
    : IterableEmbeddedMessagesPlacementId.DASHBOARD_ADS_PROD;

export const useDashboardAds = () => {
    /**
     * useContext
     */

    const { embeddedMessages, isLoadingEmbeddedMessages } =
        useContext(IterableContext);

    /**
     * End Hooks
     */

    const messages = useMemo(() => {
        if (!embeddedMessages) return null;
        return embeddedMessages[embeddedMessagesPlacementId];
    }, [embeddedMessages]);

    return {
        isLoadingEmbeddedMessages,
        messages,
    };
};
