import { useHistory } from "react-router-dom";

// Enums
import { NavigationEnum } from "scenes/Wallet/components/WalletGiftCards/constants/constants";

export const useWalletGiftCardsLandingLead = ({ setCurrentSection }) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function handleGoToLetterCompose() {
        history.push("/letters/compose");
    }

    function handleGoToPurchase() {
        setCurrentSection(NavigationEnum.PURCHASE);
    }

    return { handleGoToLetterCompose, handleGoToPurchase };
};
