import React from "react";

// Components
import { DashboardAds } from "../DashboardAds/DashboardAds";
import { DashboardAlerts } from "../DashboardAlerts/DashboardAlerts";
import { DashboardGiftCardAndWeeklyUpdates } from "../DashboardGiftCardAndWeeklyUpdates/DashboardGiftCardAndWeeklyUpdates";
import { DashboardLetters } from "../DashboardLetters/DashboardLetters";
import { DashboardNews } from "../DashboardNews/DashboardNews";
import { DashboardMessages } from "../DashboardMessages/DashboardMessages";
import { DashboardReferrals } from "../DashboardReferrals/DashboardReferrals";
import { DashboardSentLetters } from "../DashboardSentLetters/DashboardSentLetters";

export const DashboardMain = ({
    activeDraft,
    connections,
    credits,
    graduation,
    handleGraduationModalToggle,
    handlePurchaseModalToggle,
    history,
    letterCutOffTime,
    lettersSent,
    lettersSentCursor,
    weeklyUpdates,
}) => {
    return (
        <div className="page__body">
            <div className="dashboard-container" data-cy="dashboard">
                <div className="dashboard-column-one">
                    <DashboardAlerts />
                    <DashboardLetters
                        activeDraft={activeDraft}
                        credits={credits}
                        graduation={graduation}
                        handleGraduationModalToggle={
                            handleGraduationModalToggle
                        }
                        handlePurchaseModalToggle={handlePurchaseModalToggle}
                        letterCutOffTime={letterCutOffTime}
                        lettersSent={lettersSent}
                    />
                    <DashboardSentLetters
                        history={history}
                        lettersSent={lettersSent}
                        lettersSentCursor={lettersSentCursor}
                    />
                    <DashboardGiftCardAndWeeklyUpdates
                        weeklyUpdates={weeklyUpdates}
                    />
                    <DashboardReferrals connections={connections} />
                </div>
                <div className="dashboard-column-two">
                    <DashboardAds />
                    <DashboardMessages />
                    <DashboardNews />
                </div>
            </div>
        </div>
    );
};
