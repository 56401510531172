import React from "react";

// Components
import { FeatureFlagBoundary } from "components/Boundaries/FeatureFlagBoundary";
import { StorylyGroup } from "components/StorylyGroup/StorylyGroup";

// CSS
import styles from "./dashboard-header-storyly.module.scss";

// Enums
import { StorylyCategory } from "enums/StorylyCategory";

// Globals
import { featureFlags } from "globals/featureFlags";

export const DashboardHeaderStoryly = () => {
    return (
        <FeatureFlagBoundary
            featureFlags={[featureFlags.global.isStorylyContentEnabled]}
        >
            <div className={styles.dashboardHeaderStoryly}>
                <StorylyGroup category={StorylyCategory.DASHBOARD} />
            </div>
        </FeatureFlagBoundary>
    );
};
