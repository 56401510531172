import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-letters-credits.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const DashboardLettersCredits = ({
    credits,
    handlePurchaseModalToggle,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div
            className={styles.dashboardLettersCredits}
            onClick={handlePurchaseModalToggle}
            onKeyDown={(e) => {
                handleKeyDown(e, handlePurchaseModalToggle);
            }}
            tabIndex="0"
        >
            <div className={styles.label}>
                <MailOutlineIcon className={styles.icon} />
                <Text isBold>{t("letters_remaining", { ns: "common" })}</Text>
            </div>
            <Text classes={styles.value} isBold>
                {credits === null || credits === undefined ? (
                    <Skeleton width={40} />
                ) : (
                    credits
                )}
            </Text>
        </div>
    );
};

DashboardLettersCredits.propTypes = {
    credits: PropTypes.number,
    handlePurchaseModalToggle: PropTypes.func,
};
