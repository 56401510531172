import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-referrals.module.scss";

// Hooks
import { useDashboardReferrals } from "./hooks/useDashboardReferrals";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { imagePublicBase } from "utils/urlUtils";

const imageLeftUrl = `${imagePublicBase}/web/media/dashboard/referral_left.svg`;
const imageRightUrl = `${imagePublicBase}/web/media/dashboard/referral_right.svg`;

export const DashboardReferrals = ({ connections }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();
    const { bodyText, linkToParams } = useDashboardReferrals({
        connections,
    });

    /**
     * End Hooks
     */

    return (
        <div className={styles.dashboardReferrals}>
            <img
                alt={t("referrals.alt_woman_holding_letter", {
                    ns: "dashboard",
                })}
                className={styles.imageLeft}
                src={imageLeftUrl}
            />
            <div className={styles.body}>
                <div className={styles.text}>
                    <Text>{bodyText}</Text>
                </div>
                <Link to={linkToParams}>
                    <ButtonSecondary>
                        <Text>{t("refer_a_friend", { ns: "common" })}</Text>
                    </ButtonSecondary>
                </Link>
            </div>
            <img
                alt={t("referrals.alt_man_holding_letter", {
                    ns: "dashboard",
                })}
                className={styles.imageRight}
                src={imageRightUrl}
            />
        </div>
    );
};

DashboardReferrals.propTypes = {
    connections: PropTypes.array,
};
