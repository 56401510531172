import { useContext, useEffect, useState } from "react";

// Constants
import { Notifications } from "globals/notifications";

// Context
import { BundlesContext } from "context/bundles";
import { ContactsContext } from "context/contacts";
import { NotificationContext } from "context/notification/notification";

// Enums
import { SquadRole } from "../enums/SquadRole";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { createSupportSquadInstance } from "models/support-squad/SupportSquad";
import { Notification } from "models/notification/Notification";

// Utils
import {
    generateSquadIdPayload,
    generateSquadImagePayload,
} from "../utils/apiUtils";
import { getErrorMessage } from "utils/errorUtils";
import { generateImagePath } from "utils/imageUtils";
import { SandboxxRestAPI } from "utils/sandboxx";
import { NotificationSeverity } from "enums/notifications/NotificationSeverity";

export const useSupportSquad = () => {
    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState("");
    const [deleteRequestPending, setDeleteRequestPending] = useState(false);
    const [isCreatingSquad, setIsCreatingSquad] = useState(false);
    const [isFetchingSquad, setIsFetchingSquad] = useState(false);
    const [isFetchingSquads, setIsFetchingSquads] = useState(false);
    const [isUpdatingMembers, setIsUpdatingMembers] = useState(false);
    const [isUpdatingSquad, setIsUpdatingSquad] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [selectedUserListItem, setSelectedUserListItem] = useState({});
    const [shouldShowCreateModal, setShouldShowCreateModal] = useState(false);
    const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
    const [shouldShowDonateTokensModal, setShouldShowDonateTokensModal] =
        useState(false);
    const [shouldShowImageCropModal, setShouldShowImageCropModal] =
        useState(false);
    const [shouldShowJoinModal, setShouldShowJoinModal] = useState(false);
    const [shouldShowLeaveModal, setShouldShowLeaveModal] = useState(false);
    const [shouldShowNoTokensModal, setShouldShowNoTokensModal] =
        useState(false);
    const [squadAdmins, setSquadAdmins] = useState([]);
    const [squadList, setSquadList] = useState([]);
    const [squadName, setSquadName] = useState("");
    const [squadMembers, setSquadMembers] = useState([]);
    const [squadRecipientId, setSquadRecipientId] = useState("");
    const [selectedSquad, setSelectedSquad] = useState(null);
    const [supportSquadImage, setSupportSquadImage] = useState({
        imageFile: "",
        imageName: "",
        imagePreviewUrl: "",
    });

    /**
     * useContext
     */

    const { fetchBundles } = useContext(BundlesContext);
    const {
        contactModal,
        setOnSubmitContactSuccessFinalCallback,
        setOnBackButtonClickCallback,
        toggleContactModal,
    } = useContext(ContactsContext);
    const { addNotification } = useContext(NotificationContext);

    /**
     * useEffect
     */

    useEffect(() => {
        if (contactModal.type === "create") {
            const onCreateContactSuccess = (res) => {
                setCurrentSection("assign");
                setShouldShowCreateModal(true);
                setSquadRecipientId(res.abContactId);
                setSelectedUserListItem(res);
            };
            setOnSubmitContactSuccessFinalCallback(
                (res) => onCreateContactSuccess
            );
        } else if (contactModal.type === "edit") {
            const onEditContactSuccess = (id) => {
                AnalyticsLogger.logSquadUpdateRecipientAddressEnd();
                fetchSquads(id);
                toggleContactModal({
                    contactToEdit: {
                        firstName: "",
                        lastName: "",
                        title: "",
                        address: {
                            line1: "",
                            line2: "",
                            city: "",
                            state: "",
                            zipcode: "",
                        },
                    },
                    shouldShow: false,
                    targetScreen: null,
                });
            };
            setOnSubmitContactSuccessFinalCallback(
                () => (id) => onEditContactSuccess(id)
            );
        }
        const onBackButtonClick = (res) => {
            setShouldShowCreateModal(true);
        };
        setOnBackButtonClickCallback((res) => onBackButtonClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setOnBackButtonClickCallback,
        setOnSubmitContactSuccessFinalCallback,
        contactModal,
    ]);

    /**
     * End Hooks
     */

    async function changeSupportSquadRole(
        squadId,
        memberId,
        newRole,
        callback
    ) {
        setIsUpdatingMembers(true);
        try {
            const { json } = await SandboxxRestAPI.updateSupportSquadMemberRole(
                squadId,
                memberId,
                newRole
            );
            onRoleChangeSuccess(json, callback);
        } catch (err) {
            onRoleChangeFailure(err);
        }
    }

    async function createSquad(img) {
        setIsCreatingSquad(true);
        try {
            const { json } = await SandboxxRestAPI.createSupportSquad({
                ...generateSquadIdPayload({ contact: selectedUserListItem }),
                ...generateSquadImagePayload({ newImage: img }),
                squadName,
            });
            onCreateSquadSuccess(json);
        } catch (err) {
            onCreateSquadFailure(err);
        }
    }

    async function deleteSquad(callback) {
        setDeleteRequestPending(true);
        try {
            const { json } = await SandboxxRestAPI.deleteSupportSquad(
                selectedSquad.squadId
            );
            onDeleteSquadSuccess(json, selectedSquad, callback);
        } catch (err) {
            onDeleteSquadFailure(err);
        }
    }

    async function fetchSquads(id) {
        try {
            setIsFetchingSquads(true);
            const { json } = await SandboxxRestAPI.getSupportSquadList();
            onFetchSquadListSuccess(json, id);
        } catch (err) {
            onFetchSquadListFailure(err);
        }
    }

    async function fetchSquadMembers(id) {
        try {
            const { json } = await SandboxxRestAPI.getSupportSquadMembers(id);
            onFetchSquadMembersSuccess(json);
        } catch (err) {
            onFetchSquadListFailure(err);
        }
    }

    async function handleImageUpload(imageFile) {
        try {
            const imagePath = generateImagePath();
            const imageUploadFile = imageFile.imageFile;
            setIsUploadingImage(true);
            const { json } = await SandboxxRestAPI.putImageCloudfront(
                imagePath,
                imageUploadFile
            );
            validateAndSubmit(json);
        } catch (err) {
            onImageUploadFailure(err);
        }
    }

    async function handleSelectSquad(squad) {
        try {
            setIsFetchingSquad(true);
            setSquadName(squad.squadName);
            fetchSquadMembers(squad.squadId);
            const { json } = await SandboxxRestAPI.getSupportSquadItem(
                squad.squadId
            );
            onFetchSquadItemSuccess(json);
            AnalyticsLogger.logSquadViewed();
        } catch (err) {
            onFetchSquadItemFailure(err);
        }
    }

    async function joinSupportSquad(code, successCallback, errorCallback) {
        try {
            const { json } = await SandboxxRestAPI.joinSupportSquad(code);
            onJoinSupportSquadSuccess(json, successCallback);
        } catch (err) {
            onJoinSupportSquadFailure(err, errorCallback);
        }
    }

    async function leaveSquad(callback) {
        try {
            const { json } = await SandboxxRestAPI.removeSupportSquadMember(
                null,
                selectedSquad.squadId
            );
            onLeaveSquadSuccess(json, callback);
        } catch (err) {
            onLeaveSquadFailure(err, callback);
        }
    }

    function onCreateSquadFailure(err) {
        const errorMessage = getErrorMessage(err);
        addNotification(
            new Notification({
                id: "CREATE_SQUAD_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
        setIsCreatingSquad(false);
    }

    async function onCreateSquadSuccess(res) {
        const squad = await createSupportSquadInstance(res);
        setIsCreatingSquad(false);
        setSelectedSquad(squad);
        addNotification(Notifications.supportSquad.createSuccess);
        fetchSquads();
        setCurrentSection("invite");
        AnalyticsLogger.logSquadCreated({ squad: res });
    }

    function onDeleteSquadFailure(err) {
        const errorMessage = getErrorMessage(err);
        setDeleteRequestPending(false);
        addNotification(
            new Notification({
                id: "DELETE_SQUAD_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onDeleteSquadSuccess(res, squad, callback) {
        AnalyticsLogger.logSquadDeleted({ squad });
        addNotification(Notifications.supportSquad.deleteSuccess);
        callback();
        setDeleteRequestPending(false);
        setCurrentSection("name");
        setShouldShowDeleteModal(false);
        fetchSquads();
        setSelectedSquad(null);
    }

    function onFetchSquadItemFailure(err) {
        const errorMessage = getErrorMessage(err);
        addNotification(
            new Notification({
                id: "FETCH_SQUAD_ITEM_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
        setIsFetchingSquad(false);
        setIsUpdatingMembers(false);
    }

    async function onFetchSquadItemSuccess(res) {
        const squad = await createSupportSquadInstance(res);
        setSelectedSquad(squad);
        fetchBundles({
            id: res.squadId,
            zipcode: res.supporteeAddress.zipcode,
        });
        const tgtElement = document.getElementById(
            `support-squad-list-item-${res.squadId}`
        );
        tgtElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
        });
        setIsFetchingSquad(false);
        setIsFetchingSquads(false);
        setIsUpdatingMembers(false);
    }

    function onFetchSquadListFailure(err) {
        setIsFetchingSquads(false);
        setIsUpdatingMembers(false);
        const errorMessage = getErrorMessage(err);
        addNotification(
            new Notification({
                id: "FETCH_SQUAD_LIST_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onFetchSquadListSuccess(res, id) {
        setIsFetchingSquads(false);
        setSquadList(res);
        if (id) {
            const squad = res.find((item) => item.squadId === id);
            handleSelectSquad(squad);
        } else {
            setIsUpdatingMembers(false);
        }
    }

    function onImageUploadFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIsCreatingSquad(false);
        setIsUploadingImage(false);
        addNotification(
            new Notification({
                id: "IMAGE_UPLOAD_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onJoinSupportSquadSuccess(res, callback) {
        callback && callback(res.message);
        fetchSquads();
    }

    function onJoinSupportSquadFailure(res, callback) {
        callback && callback(res);
    }

    function onLeaveSquadFailure(err, callback) {
        const errorMessage = getErrorMessage(err);
        addNotification(
            new Notification({
                id: "LEAVE_SQUAD_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
        callback();
    }

    function onLeaveSquadSuccess(res, callback) {
        setShouldShowLeaveModal(false);
        fetchSquads();
        setSelectedSquad(null);
        callback();
    }

    function onFetchSquadMembersSuccess(res) {
        setSquadMembers(res.filter((mem) => mem.role !== SquadRole.ADMIN));
        setSquadAdmins(res.filter((mem) => mem.role === SquadRole.ADMIN));
    }

    function onRemoveSquadMemberFailure(err) {
        const errorMessage = getErrorMessage(err);
        addNotification(
            new Notification({
                id: "REMOVE_SQUAD_MEMBER_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
        setIsUpdatingMembers(false);
    }

    function onRemoveSquadMemberSuccess(res, member, callback) {
        AnalyticsLogger.logSquadMemberDeleted({ member, squad: selectedSquad });
        setSquadMembers((prev) =>
            prev.filter((item) => item.memberId !== res.memberId)
        );
        fetchSquads(selectedSquad.squadId);
        callback && callback();
    }

    function onRoleChangeFailure(err) {
        const errorMessage = getErrorMessage(err);
        addNotification(
            new Notification({
                id: "ROLE_CHANGE_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
        setIsUpdatingMembers(false);
    }

    function onRoleChangeSuccess(res, callback) {
        callback && callback();
        fetchSquads(selectedSquad.squadId);
    }

    function onUpdateSquadFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIsCreatingSquad(false);
        setIsUpdatingSquad(false);
        addNotification(
            new Notification({
                id: "UPDATE_SQUAD_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onUpdateSquadSuccess(res, id) {
        addNotification(Notifications.supportSquad.updateSuccess);
        setShouldShowCreateModal(false);
        setIsCreatingSquad(false);
        setIsUpdatingSquad(false);
        fetchSquads(id);
    }

    function removeSquadMembers(members, squadId, callback) {
        setIsUpdatingMembers(true);
        members.forEach(async (member) => {
            try {
                const { json } = await SandboxxRestAPI.removeSupportSquadMember(
                    member.memberId,
                    squadId
                );
                onRemoveSquadMemberSuccess(json, member, callback);
            } catch (err) {
                onRemoveSquadMemberFailure(err);
            }
        });
    }

    async function updateSquad(img) {
        setIsUpdatingSquad(true);
        try {
            const imagePayload = generateSquadImagePayload({
                newImage: img,
                previousImage: {
                    headerLargeUrl: selectedSquad.headerLargeUrl,
                    headerSmallUrl: selectedSquad.headerSmallUrl,
                },
            });
            const { json } = await SandboxxRestAPI.updateSupportSquad({
                ...imagePayload,
                squadId: selectedSquad.squadId,
                squadName,
                supporteeAddress: selectedSquad.supporteeAddress,
                supporteeFirstName: selectedSquad.supporteeFirstName,
                supporteeFullName: selectedSquad.supporteeFullName,
                supporteeLastName: selectedSquad.supporteeLastName,
                supporteeTitle: selectedSquad.supporteeTitle,
            });
            onUpdateSquadSuccess(json, selectedSquad.squadId);
        } catch (err) {
            onUpdateSquadFailure(err);
        }
    }

    function validateAndSubmit(img) {
        setIsUploadingImage(false);
        if (currentSection === "assign") {
            createSquad(img);
        } else if (currentSection === "edit-details") {
            updateSquad(img);
        }
    }

    return {
        changeSupportSquadRole,
        currentSection,
        deleteRequestPending,
        deleteSquad,
        fetchSquads,
        fetchSquadMembers,
        handleImageUpload,
        handleSelectSquad,
        isCreatingSquad,
        isFetchingSquad,
        isFetchingSquads,
        isUpdatingMembers,
        isUpdatingSquad,
        isUploadingImage,
        joinSupportSquad,
        leaveSquad,
        removeSquadMembers,
        selectedUserListItem,
        setCurrentSection,
        setIsFetchingSquad,
        setIsFetchingSquads,
        setSelectedUserListItem,
        setShouldShowCreateModal,
        setShouldShowDeleteModal,
        setShouldShowDonateTokensModal,
        setShouldShowImageCropModal,
        setShouldShowJoinModal,
        setShouldShowLeaveModal,
        setShouldShowNoTokensModal,
        setSquadName,
        setSquadRecipientId,
        setSupportSquadImage,
        setSquadList,
        setSelectedSquad,
        shouldShowCreateModal,
        shouldShowDeleteModal,
        shouldShowDonateTokensModal,
        shouldShowImageCropModal,
        shouldShowJoinModal,
        shouldShowLeaveModal,
        shouldShowNoTokensModal,
        squadAdmins,
        squadList,
        squadMembers,
        squadName,
        squadRecipientId,
        selectedSquad,
        supportSquadImage,
        toggleContactModal,
        validateAndSubmit,
    };
};
