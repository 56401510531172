import { useMemo } from "react";
import { useHistory } from "react-router-dom";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { isEmptyObject } from "utils/objectUtils";

export const useDashboardLetters = ({ activeDraft }) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useMemo
     */

    const hasActiveDraft = useMemo(
        () =>
            activeDraft &&
            !isEmptyObject(activeDraft) &&
            !!activeDraft?.draftRecipient,
        [activeDraft]
    );

    /**
     * End Hooks
     */

    function handleComposeClick() {
        AnalyticsLogger.logLetterComposeStart();
        history.push("/letters/compose");
        activeDraft?.draftRecipient &&
            localStorage.setItem(
                "sandboxxMessageRecipient",
                JSON.stringify(activeDraft.draftRecipient)
            );
    }

    return {
        hasActiveDraft,
        handleComposeClick,
    };
};
